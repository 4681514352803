/* eslint-disable import/no-unresolved */

import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import Config from '../../../../../config';

const embeddedCode = `<script>  
          // Initialize the agent on page load.
          const fpPromise = import('https://fpjscdn.net/v3/POa9umjaO8vlDgS89zof/')
          .then(FingerprintJS => FingerprintJS)
</script>`;

function URLEmbed() {
  const [scheduleId, setScheduleId] = useState('');
  const [formId, setFormId] = useState('');

  useEffect(() => {
    axios.get(`${Config.url || ''}/api/v1/user/back_office/get_schedule`)
      .then((data) => {
        const schedule = data?.data?.data?.schedule;
        setScheduleId(schedule?._id);
      }).catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios.get(`${Config.url || ''}/api/v1/user/back_office/get_form`)
      .then((data) => {
        const form = data?.data?.data;
        setFormId(form._id);
      }).catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCopyScheduleURL = () => {
    navigator.clipboard.writeText(`${window.location.origin}/get-schedule?scheduleId=${scheduleId}&&formId=${formId}`).then(() => {
      toast.success('URL copied to clipboard', {
        position: 'top-center',
        autoClose: 1000,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className="bg-[#f2f4f7] rounded-2xl p-6 space-y-4">
      <div>
        <p className="text-slate-800 text-base font-semibold leading-normal mb-2">
          Direct Link
        </p>
        <p className="text-slate-500 text-sm font-normal leading-tight">Use this link to your CTA</p>
      </div>
      {
        scheduleId && formId && (
          <div className="space-y-3">
            <div className="px-[13px] py-3 bg-white rounded-md border border-slate-300">
              {/* <div className="self-stretch h-6 text-gray-500 text-base font-normal font-['DM Mono'] leading-normal">https://saybriefly.com/user-name</div> */}
              <input type="text" value={`${window.location.origin}/get-schedule?scheduleId=${scheduleId}&formId=${formId}`} className="w-full outline-none border-none bg-transparent focus:ring-0 focus:outline-none" readOnly />
            </div>
            <button onClick={handleCopyScheduleURL} className="px-3 py-2 bg-black rounded-lg shadow border border-black text-neutral-50 text-sm font-medium leading-tight">
              Copy
            </button>
          </div>
        )
      }

      <div>
        <p className="text-slate-800 text-base font-semibold leading-normal mb-2">
          HTML Embedding
        </p>
        <p className="text-slate-500 text-sm font-normal leading-tight">Use this code to you website to embed</p>
      </div>

      <div className="space-y-3">
        <div className="px-[13px] py-3 bg-white rounded-md border border-slate-300">
          {/* <div className="self-stretch h-6 text-gray-500 text-base font-normal font-['DM Mono'] leading-normal">https://saybriefly.com/user-name</div> */}
          <textarea type="text" value={embeddedCode} className="w-full h-[160px] outline-none border-none bg-transparent focus:ring-0 focus:outline-none resize-none" readOnly />
        </div>
        <button onClick={handleCopyScheduleURL} className="px-3 py-2 bg-black rounded-lg shadow border border-black text-neutral-50 text-sm font-medium leading-tight">
          Copy
        </button>
      </div>
    </div>
  );
}

export default URLEmbed;
