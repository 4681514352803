import axios from 'axios';
import Config from '../config';

const loginWithGoogle = (token) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/auth/google-login`,
    data: { id_token: token },
  });
};

export default loginWithGoogle;
