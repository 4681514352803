/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import {
    FiChevronDown, FiChevronLeft, FiChevronUp, FiMenu,
} from 'react-icons/fi';
import { useEffect, useRef } from 'react';
import LittleStreams from './LittleStreams';

export default function TopBar({
    localStream, video, showPanel, setShowPanel, topBar, setTopBar, streams, setOver, isScreen,
}) {
    const localVideoRef = useRef(null);

    useEffect(() => {
        if (!localStream) return;
        localVideoRef.current.srcObject = localStream;
    }, [localStream]);

    return (
        <div className="meeting-top-controls">
            <div
                className="panel-control"
                onClick={() => {
                    setShowPanel(!showPanel);
                    setOver(showPanel);
                }}
            >
                {showPanel ? <FiChevronLeft /> : <FiMenu />}
            </div>
            <LittleStreams streams={streams} />
            <div className="videos" style={{ flexGrow: 0 }}>
                <video
                    hidden={!video && !isScreen}
                    className="video"
                    onLoadedMetadata={() => localVideoRef?.current?.play()}
                    ref={localVideoRef}
                    style={{ objectFit: 'cover', zIndex: 1000 }}
                    playsInline
                />
            </div>
            <div className="panel-control" onClick={() => setTopBar(!topBar)}>
                {topBar ? <FiChevronDown /> : <FiChevronUp />}
            </div>
        </div>
    );
}
