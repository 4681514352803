/* eslint-disable no-unused-vars */
import { Editor } from '@tinymce/tinymce-react';
import html2pdf from 'html2pdf.js';
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import getBriefs from '../../../actions/getBriefs';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogTitle } from '../../../components/ui/dialog';
import {
  Pagination, PaginationContent,
  PaginationItem,
  PaginationLink,
} from '../../../components/ui/pagination';
import Config from '../../../config';
import BriefFilter from '../components/brief-filter';
import DateRangePicker from '../components/date-range-picker';
import Layout from '../layout';

function MyBriefs() {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [briefData, setBriefData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const [selectedBrief, setSelectedBrief] = useState(null);
  const [showBriefDetails, setShowBriefDetails] = useState(false);

  useEffect(() => {
    try {
      const getData = async () => {
        const { data } = await getBriefs(page, selectedFilter);
        if (data?.success) {
          setBriefData(data?.data?.briefs);
          setPage(data?.data?.pagination?.currentPage);
          setMaxPage(data?.data?.pagination?.totalPages);
        }
      };
      getData();
    } catch (e) {
      console.log(e);
    }
  }, [selectedFilter]);

  const handleDownloadBrief = () => {
    if (!selectedBrief) {
      return;
    }
    const content = marked(selectedBrief?.html);

    const container = document.createElement('div');
    container.innerHTML = content;
    container.appendChild(document.createElement('br'));
    // container.appendChild(document.createElement('br'));
    document.body.appendChild(container);

    // Calculate the height based on content
    const height = container.scrollHeight;
    const pageHeight = (height / 80); // Convert px to inches (assuming 90px per inch)

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // margins
      filename: `brief-${selectedBrief._id}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: [8.5, pageHeight], orientation: 'portrait' },
    };

    html2pdf().from(container).set(opt).save()
      .then(() => {
        document.body.removeChild(container);
      });
  };

  return (
    <Layout>
      <div className="space-y-6">
        <div className="space-y-1">
          <h2 className="text-[#101828] text-3xl font-semibold leading-[38px]">All Briefs</h2>
          <p className="text-[#475467] text-base font-normal leading-normal">Negative all the briefs you have generated overtime. </p>
        </div>
        <div className="flex justify-between items-center">
          <BriefFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
          <DateRangePicker />
        </div>

        <table className="w-full rounded-xl overflow-hidden">
          <thead className="bg-[#eaecf0] border-b border-[#eaecf0]">
            <tr>
              <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3 w-[50%]">
                Title
              </td>
              <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">
                Date
              </td>
              <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3 text-right">
                Details
              </td>
            </tr>
          </thead>
          <tbody>
            {
              briefData.map((brief, i) => (
                <tr key={i} className="border-b border-[#eaecf0]">
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      <img className="w-[40px] h-[40px] rounded-full object-cover object-center" src={brief?.meeting?.users[1].picture?.location ? `${Config.url || ''}/${brief?.meeting?.users[1].picture?.location}` : '/image/placeholder/avatar.png'} alt="" />
                      <div>
                        <h4 className="text-[#101828] text-sm font-normal leading-tight mb-1">
                          {brief?.meeting?.users[1].firstName}
                          {' '}
                          {brief?.meeting?.users[1].lastName}
                        </h4>
                        <p className="text-[#475467] text-xs font-normal leading-tight">
                          Between
                          {' '}
                          {`${brief?.access[0]?.firstName} ${brief?.access[0]?.lastName}`}
                          {brief?.access?.length > 2 ? `, ${brief?.access[1]?.firstName} ${brief?.access[1]?.lastName} and ${(brief?.access?.length || 0) - 2} others` : brief?.access?.length === 2 ? ` and ${brief?.access[1].firstName} ${brief?.access[1].lastName}` : ' and others.'}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <p className="text-sm font-normal leading-tight">
                      {new Date(brief?.createdAt)?.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}
                    </p>
                  </td>
                  <td className="flex justify-end py-4 px-6">
                    <Button onClick={() => {
                      setSelectedBrief(brief);
                      setShowBriefDetails(true);
                    }}
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Pagination>
          <PaginationContent className="space-x-4">
            <PaginationItem>
              <Button onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))} className={`${page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'} bg-white hover:bg-gray-100 border`}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="arrow-left">
                    <path id="Icon" d="M16.3332 10.0003H4.6665M4.6665 10.0003L10.4998 15.8337M4.6665 10.0003L10.4998 4.16699" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </Button>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink>
                Page
                {' '}
                {page}
                {' '}
                of
                {' '}
                {maxPage}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <Button onClick={() => setPage((prev) => ((briefData?.length === 0 || briefData?.length < 10) ? prev : prev + 1))} className={`${page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'} bg-white hover:bg-gray-100 border`}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="arrow-right">
                    <path id="Icon" d="M4.6665 10.0003H16.3332M16.3332 10.0003L10.4998 4.16699M16.3332 10.0003L10.4998 15.8337" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </Button>
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>

      <Dialog open={showBriefDetails} onOpenChange={setShowBriefDetails}>
        <DialogContent className="p-0">
          <DialogTitle className="hidden" />
          <div className="bg-[#EAECF0] p-6 rounded-2xl space-y-5 max-h-[600px] overflow-auto scroll-none">
            <div>
              <h4 className="text-gray-900 text-lg font-semibold mb-1">Brief Details</h4>
              <p className="text-[#475467] text-sm font-normal leading-tight">
                Between
                {' '}
                {`${selectedBrief?.access[0]?.firstName} ${selectedBrief?.access[0]?.lastName}`}
                {selectedBrief?.access?.length > 2 ? `, ${selectedBrief?.access[1]?.firstName} ${selectedBrief?.access[1]?.lastName} and ${(selectedBrief?.access?.length || 0) - 2} others` : selectedBrief?.access?.length === 2 ? ` and ${selectedBrief?.access[1].firstName} ${selectedBrief?.access[1].lastName}` : ' and others.'}
              </p>
            </div>
            <Editor
              apiKey="qvctqtfhdqwqkjf8r0rd2dbjuk44fzk70v0sosx67u0z5msk"
              // onInit={(evt, editor) => editorRef.current = editor}
              initialValue={selectedBrief && marked(selectedBrief?.html)}
              init={{
                height: 300,
                menubar: false,
                toolbar: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
              disabled
            />

            <div className="flex gap-6">
              <Link to={`/brief/${selectedBrief?.meeting?._id}`}>
                <Button>
                  Edit Brief
                </Button>
              </Link>
              <Button onClick={handleDownloadBrief} variant="outline" className="flex gap-1 items-center">
                Download Brief
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default MyBriefs;
