import axios from 'axios';
import Config from '../config';

const markAsRead = (notificationId) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/mark_notification_as_read`,
    data: { notificationId },
  });
};

export default markAsRead;
