/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useMemo } from 'react';
import config from '../../../config';

const RoomCaptions = React.memo(({ room }) => {
    // Use useMemo to prevent recalculating captions every render
    const captionsToDisplay = useMemo(() => {
        if (!room?.captions?.length) return [];

        const currentTime = new Date().getTime();

        return room.captions.filter((caption) => caption.showingTime > currentTime && caption.transcription !== '');
    }, [room]);

    console.log(captionsToDisplay, 'captionsToDisplay');

    return captionsToDisplay.length > 0 && (
        <div className="flex p-4 rounded-xl fixed bottom-40 mx-40">
            {captionsToDisplay.map((caption, index) => (
                <>
                    <span className="py-2 px-2 bg-[#000000b3] text-white flex gap-2 items-center" key={caption.timestamp}>
                        {index === 0 || captionsToDisplay[index - 1]?.user?._id?.toString() !== caption.user?._id?.toString() ? <><img src={caption.user?.picture?.location ? `${config.url}/${caption.user?.picture?.location}` : '/image/placeholder/avatar.png'} className="w-10 h-10 rounded-full" alt="PP" /> <span className="font-bold">{caption.user?.firstName} :</span></> : ''}
                        <span className="">{caption.transcription}</span>
                    </span>
                    {(captionsToDisplay[index - 1]?.user?._id?.toString() !== caption.user?._id?.toString()) ? (
                        <br />
                    ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <></>
                    )}
                </>
            ))}
        </div>
    );
});

export default RoomCaptions;
