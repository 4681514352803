/* eslint-disable indent */
import {
    CaretSortIcon,
} from '@radix-ui/react-icons';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../../components/ui/table';

const data = [
    {
        id: 1,
        invoice: 'Invoice #007 - Dec 2022',
        amount: 316,
        status: 'paid',
        billingDate: 'Dec 1, 2022',
        plan: 'Basic Plan',
    },
    {
        id: 1,
        invoice: 'Invoice #006 - Dec 2022',
        amount: 218,
        status: 'paid',
        billingDate: 'Nov 21, 2022',
        plan: 'Basic Plan',
    },
    {
        id: 1,
        invoice: 'Invoice #005 - Dec 2022',
        amount: 298,
        status: 'paid',
        billingDate: 'Nov 20, 2022',
        plan: 'Basic Plan',
    },

];

export const columns = [
    {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={
                    table.getIsAllPageRowsSelected()
                    || (table.getIsSomePageRowsSelected() && 'indeterminate')
                }
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'invoice',
        header: 'Invoice',
        cell: ({ row }) => (
          <div className="capitalize flex items-center gap-2">
            <img src="/image/icons/pdf.svg" className="w-8 h-8" alt="" />
            {row.getValue('invoice')}
          </div>
        ),
    },
    {
        accessorKey: 'billingDate',
        header: ({ column }) => {
            return (
              <Button
                className="p-0 hover:bg-transparent"
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
              >
                Billing date
                <CaretSortIcon className="ml-2 h-4 w-4" />
              </Button>
            );
        },
        cell: ({ row }) => <div className="capitalize">{row.getValue('billingDate')}</div>,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => (
          <div className="inline-flex px-2 py-1 bg-emerald-50 rounded-full border border-emerald-200 items-center gap-1">
            <img src="/image/icons/check.svg" alt="" className="w-3 h-3" />
            <p className="text-emerald-700 text-xs font-medium m-0 capitalize">{row.getValue('status')}</p>
          </div>
        ),
    },
    {
        accessorKey: 'amount',
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                className="p-0"
                onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
              >
                Amount
                <CaretSortIcon className="ml-2 h-4 w-4" />
              </Button>
            );
        },
        cell: ({ row }) => (
          <div className="uppercase">
            USD $
            {row.getValue('amount')}
          </div>
        ),
    },
    {
        accessorKey: 'plan',
        header: 'Plan',
        cell: ({ row }) => (
          <p className="m-0 text-slate-600 text-sm font-normal leading-tight">{row.getValue('plan')}</p>
        ),
    },
    {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
            const payment = row.original;
            return (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="p-0">
                    <span>Download</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuItem onClick={() => console.log(payment)}>
                    Download PDF
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>View payment details</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            );
        },
    },
];

export default function InvoiceTable() {
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });

    return (
      <div className="w-full">
        {/* <div className="flex items-center py-4">
        <Input
          placeholder="Filter emails..."
          value={(table.getColumn('email')?.getFilterValue()) ?? ''}
          onChange={(event) => table.getColumn('email')?.setFilterValue(event.target.value)}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns
              {' '}
              <ChevronDownIcon className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div> */}
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                                    return (
                                      <TableHead key={header.id}>
                                        {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                      </TableHead>
                                    );
                                })}
                </TableRow>
                        ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                              <TableRow
                                key={row.id}
                                data-state={row.getIsSelected() && 'selected'}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <TableCell key={cell.id}>
                                    {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                  </TableCell>
                                    ))}
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={columns.length}
                              className="h-24 text-center"
                            >
                              No results.
                            </TableCell>
                          </TableRow>
                        )}
            </TableBody>
          </Table>
        </div>
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length}
            {' '}
            of
            {' '}
            {table.getFilteredRowModel().rows.length}
            {' '}
            row(s) selected.
          </div>
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
}
