import { useEffect, useRef } from 'react';
import Config from '../../../config';
import './Interface.sass';

function Interface({
  audio, video, peer, isMaximized, isScreen,
}) {
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!audio) return;
    if (audio) audioRef.current.srcObject = audio;
  }, [audio]);

  useEffect(() => {
    if (!video) return;
    if (video) videoRef.current.srcObject = video;
  }, [video]);

  return (
    <div className="interface uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-height-1-1">
      {audio && (
        <audio
          ref={audioRef}
          onLoadedMetadata={() => audioRef.current.play()}
          className="remote-audio"
          controls={false}
          hidden
          data-user={peer}
        />
      )}
      {video && (
        <video
          ref={videoRef}
          onLoadedMetadata={() => videoRef.current.play()}
          className="remote-video"
          playsInline
          controls={false}
          hidden={false}
          data-user={peer}
          style={{ objectFit: !isMaximized || isScreen ? 'contain' : 'cover' }}
        />
      )}
      {/* {!video && (
        <div className="remote-peer">
          <div className="name">
            {peer?.firstName}
            {' '}
            {peer?.lastName}
          </div>
          <Picture user={peer} />
          <div className="status">{!video && !audio ? 'Spectator' : 'Audio Only'}</div>
        </div>
      )} */}
      {!video && (
        <div className="w-[250px] bg-red-200 bg-opacity-50 max-h-[250px] flex-grow bg-center bg-cover bg-no-repeat rounded-lg flex flex-col justify-end relative overflow-hidden">
          {
            peer?.user?.picture ? <img src={`${Config.url || ''}/api/images/${peer.user.picture.shieldedID}/256`} alt="Picture" className="h-full w-full" /> : (
              <span className="w-full h-full flex items-center justify-center text-6xl text-gray-200">
                {peer?.firstName.substr(0, 1).toUpperCase()}
                {peer?.lastName.substr(0, 1).toUpperCase()}
              </span>
            )
          }
          <div className="w-full h-full absolute top-0 left-0 p-4 flex items-end">
            <div className="p-2 px-4 bg-white rounded-lg justify-start items-start gap-1.5 inline-flex">
              {/* <div className="w-6 h-6 relative" /> */}
              {
                audio && (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="microphone-02">
                    <path id="Icon" d="M20 12V13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13V12M12 17C9.79086 17 8 15.2091 8 13V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V13C16 15.2091 14.2091 17 12 17Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
                )
              }

              <div className="text-gray-900 text-base font-semibold leading-normal">
                {peer?.firstName}
                {' '}
                {peer?.lastName}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Interface;
