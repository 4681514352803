/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import getContactWithMeetingHistory from '../../../actions/getContactWithMeetingHistory';
import { Button } from '../../../components/ui/button';
import ContactTab from './contact-tab';
import CreateMeetingButton from './create-meeting-button';
import Config from '../../../config';

function ContactDetails({ selectedContact }) {
  const [meetingsData, setMeetingsData] = useState(null);
  const [page, setPage] = useState(1);

  const { _id, contact_user: contactUser } = selectedContact;

  useEffect(() => {
    try {
      const getData = async () => {
        const { data } = await getContactWithMeetingHistory(_id, page);
        if (data?.success) {
          setMeetingsData(data?.data?.meetings);
          // setSelectedContact(data?.data[0]);
        }
      };
      getData();
    } catch (error) {
      console.log(error);
    }
  }, [_id, page]);

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-3 py-4">
        <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex items-center justify-center">
          <img
            className="w-full h-full object-cover"
            src={`${
              contactUser?.picture?.location
                ? `${Config.url || ''}/${contactUser?.picture?.location}`
                : '/image/placeholder/avatar.png'
            }`}
            alt="Profile"
          />
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-gray-900 text-sm font-semibold leading-tight my-0">
            {contactUser?.firstName} {contactUser?.lastName} - {contactUser?.isGuest === true ? 'Guest User' : 'Saybriefly User'}
          </h4>
          <div className="flex gap-2 text-sm text-slate-600">
            <a href="mailto:olivia@untitledui.com" className="my-0">
              {contactUser?.email}
            </a>
          </div>
        </div>
      </div>
      <div className="flex gap-6 justify-between">
        <div className="flex-col gap-2 inline-flex">
          <div className="text-slate-600 text-sm font-medium leading-tight">Email</div>
          <a href={`mailto:${contactUser?.email}`} className="inline-flex items-center gap-2">
            <div className="text-zinc-800 text-base font-semibold leading-normal">{contactUser?.email}</div>
            <img src="/image/icons/arrow-up-right.svg" alt="" className="w-5 h-5" />
          </a>
        </div>
        <div className="flex-col gap-2 inline-flex">
          <div className="text-slate-600 text-sm font-medium leading-tight">Location</div>
          <div className="text-zinc-800 text-base font-semibold leading-normal">{contactUser?.location || 'N/A'}</div>
        </div>
        <div className="flex-col gap-2 inline-flex">
          <div className="text-slate-600 text-sm font-medium leading-tight">Company Name</div>
          <div className="text-zinc-800 text-base font-semibold leading-normal">{contactUser?.companyName || 'N/A'}</div>
        </div>
      </div>
      <hr />
      <div className="flex gap-6">
        <CreateMeetingButton />
        <a href={`mailto:${contactUser?.email}`}>
          <Button className="flex items-center gap-2 bg-black text-white">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="mail-02">
                <g id="Solid">
                  <path
                    d="M1.72143 6.8429C1.51964 6.70046 1.41874 6.62924 1.33271 6.61186C1.14316 6.57354 0.946901 6.67523 0.868894 6.85219C0.833491 6.9325 0.833493 7.05464 0.833496 7.29891V12.7011C0.833486 13.3719 0.833478 13.9255 0.870325 14.3765C0.890092 14.6185 1.17215 14.7158 1.35206 14.5529L5.68055 10.6318C5.89143 10.4407 5.99687 10.3452 6.03049 10.2355C6.05997 10.1394 6.05367 10.0358 6.01277 9.94394C5.96612 9.83916 5.84989 9.75711 5.61743 9.59302L1.72143 6.8429Z"
                    fill="white"
                  />
                  <path
                    d="M2.24279 15.9948C2.0731 16.1485 1.98825 16.2254 1.95467 16.3353C1.92863 16.4204 1.93642 16.546 1.97281 16.6273C2.01973 16.7321 2.09712 16.7864 2.25189 16.8951C2.37938 16.9845 2.51354 17.0654 2.65353 17.1367C3.05798 17.3428 3.48856 17.4249 3.95698 17.4632C4.40797 17.5 4.96159 17.5 5.63239 17.5H14.3679C15.0387 17.5 15.5924 17.5 16.0433 17.4632C16.5118 17.4249 16.9424 17.3428 17.3468 17.1367C17.4868 17.0654 17.6209 16.9845 17.7484 16.8951C17.9032 16.7864 17.9806 16.7321 18.0275 16.6272C18.0639 16.5459 18.0717 16.4204 18.0457 16.3353C18.0121 16.2254 17.9272 16.1485 17.7575 15.9948L12.6578 11.375C12.5603 11.2867 12.5116 11.2426 12.4589 11.2167C12.359 11.1678 12.2436 11.1608 12.1385 11.1973C12.0831 11.2166 12.0294 11.2545 11.922 11.3304C11.4659 11.6533 11.0654 11.9369 10.6087 12.0515C10.2093 12.1517 9.79121 12.1517 9.39174 12.0515C8.93501 11.9369 8.53458 11.6533 8.0785 11.3304C7.97104 11.2545 7.91731 11.2165 7.86178 11.1972C7.75673 11.1607 7.64144 11.1677 7.54158 11.2167C7.48879 11.2425 7.44004 11.2867 7.34254 11.375L2.24279 15.9948Z"
                    fill="white"
                  />
                  <path
                    d="M18.6483 14.5529C18.8282 14.7158 19.1102 14.6185 19.13 14.3765C19.1668 13.9255 19.1668 13.3719 19.1668 12.7011V7.2989C19.1668 7.05472 19.1668 6.93263 19.1315 6.85235C19.0535 6.67533 18.8571 6.57361 18.6675 6.61196C18.5816 6.62936 18.4807 6.70055 18.279 6.84294L14.383 9.59307C14.1505 9.75716 14.0343 9.8392 13.9876 9.94399C13.9467 10.0359 13.9404 10.1394 13.9699 10.2356C14.0035 10.3453 14.109 10.4408 14.3198 10.6318L18.6483 14.5529Z"
                    fill="white"
                  />
                  <path
                    d="M18.5665 3.92274C18.6575 4.05265 18.703 4.1176 18.717 4.2164C18.7279 4.2934 18.7092 4.40075 18.6729 4.46951C18.6263 4.55774 18.5475 4.61337 18.3899 4.72461L11.0575 9.90041C10.4469 10.3314 10.3174 10.4063 10.2031 10.4349C10.0699 10.4684 9.93056 10.4684 9.7974 10.4349C9.68308 10.4063 9.55356 10.3314 8.94298 9.90041L1.61051 4.72454C1.45291 4.6133 1.37411 4.55767 1.3275 4.46944C1.29117 4.40068 1.27246 4.29332 1.28338 4.21633C1.29738 4.11753 1.34288 4.05257 1.43386 3.92266C1.74576 3.47733 2.17016 3.1096 2.65353 2.86331C3.05798 2.65724 3.48856 2.5751 3.95698 2.53683C4.40797 2.49998 4.9616 2.49999 5.6324 2.5H14.3679C15.0387 2.49999 15.5924 2.49998 16.0433 2.53683C16.5118 2.5751 16.9424 2.65724 17.3468 2.86331C17.8302 3.10962 18.2546 3.47737 18.5665 3.92274Z"
                    fill="currentColor"
                  />
                </g>
              </g>
            </svg>
            Send Mail
          </Button>
        </a>
      </div>
      <hr />
      <div>
        <ContactTab meetingsData={meetingsData} meetingPage={page} setMeetingPage={setPage} />
      </div>
    </div>
  );
}

export default ContactDetails;
