import InvoiceTableContainer from '../components/invoice-table-container';
import Package from '../components/package';
import PageHeading from '../components/page-heading';
import PaymentMethod from '../components/payment-methode';
import Layout from '../layout';

function BillingPage() {
  return (
    <Layout>
      <div className="space-y-8">
        <div>
          <PageHeading title="Billing" />
          <p className="text-slate-600 text-base font-normal leading-normal m-0">Manage your billing and payment details.</p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
          <div>
            <Package />
          </div>
          <div>
            <PaymentMethod />
          </div>
        </div>
        <InvoiceTableContainer />
      </div>
    </Layout>
  );
}

export default BillingPage;
