/* eslint-disable indent */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
import {
  Pagination, PaginationContent,
  PaginationItem,
  PaginationNext, PaginationPrevious,
} from '../../../components/ui/pagination';
import { getHourMinuteDifference } from './meeting-card-item';
import Config from '../../../config';

function ContactListing({
  contacts, search, selectedContact, setSelectedContact, page, setPage,
}) {
  const lowercasedSearch = search.toLowerCase();
  const filteredContacts = contacts
    ?.filter((item) => {
      if (lowercasedSearch === '') {
        return true;
      }

      const {
        firstName, lastName, email, phone, _id,
      } = item.contact_user || {};

      return (
        (firstName && firstName.toLowerCase().includes(lowercasedSearch))
        || (lastName && lastName.toLowerCase().includes(lowercasedSearch))
        || (email && email.toLowerCase().includes(lowercasedSearch))
        || (phone && phone.includes(search))
        || (_id && _id.toLowerCase().includes(lowercasedSearch))
      );
    });
  return (
    <div className="space-y-6 w-full">
      <div className="space-y-4 max-h-[55vh] overflow-y-scroll scroll-none">
        {filteredContacts?.map((item, i) => (
          <div key={i} onClick={() => setSelectedContact(item)} className={`flex items-center gap-3 p-4 border-b hover:bg-gray-100 cursor-pointer ${selectedContact?._id === item._id ? 'bg-gray-100' : ''}`}>
            <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex items-center justify-center">
              <img className="w-full h-full object-cover" src={`${
              item?.contact_user?.picture?.location
                ? `${Config.url || ''}/${item?.contact_user?.picture?.location}`
                : '/image/placeholder/avatar.png'
            }`} alt="Profile"
              />
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="text-gray-900 text-sm font-semibold leading-tight my-0">
                {item?.contact_user?.firstName}
                {' '}
                {item?.contact_user?.lastName}
              </h4>
              <div className="flex gap-2 text-sm text-slate-600">
                <p>
                  {new Date(item?.contact_user?.createdAt).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
                </p>

                <div>
                  {item?.contact_user?.lastOnline ? getHourMinuteDifference(new Date(), item?.contact_user?.lastOnline || 0) === '0m' ? (
                    <div className="flex items-center gap-1">
                      <span className="relative flex h-1.5 w-1.5 rounded-full bg-green-500 animate-pulse" />
                      <span>Online</span>
                    </div>
                  ) : ` - Active ${getHourMinuteDifference(new Date(), item?.contact_user?.lastOnline || 0)} ago` : 'Not Online yet'}
                </div>
              </div>
            </div>
          </div>
        ))}

        {
          filteredContacts.length === 0 && 'No contacts found.'
        }
      </div>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious onClick={() => setPage((prev) => (prev < 1 ? 1 : prev - 1))} className={page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'} />
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              onClick={() => setPage((prev) => (filteredContacts?.length === 0 ? prev : prev + 1))}
              className={((filteredContacts?.length === 0 && page > 1) || filteredContacts?.length < 10) ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>

    </div>
  );
}

export default ContactListing;
