import axios from 'axios';
import Config from '../config';

const getBriefs = (page, status) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/briefs?page=${page || 1}&limit=10&status=${status || 'all'}`,
    data: {},
  });
};

export default getBriefs;
