/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../../../config';
import CreateMeetingButton from '../components/create-meeting-button';
import MeetingHistory from '../components/meetings-history';
import UpcomingMeetings from '../components/upcoming-meetings';
import WelcomeHeading from '../components/welcome-heading';
import Layout from '../layout';

function DashboardIndex() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    axios.get(`${Config.url || ''}/api/v1/user/dashboard-brief-stats`)
      .then((data) => {
        setStats(data?.data?.data);
      }).catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <div className="space-y-6">
        <div className="flex justify-between">
          <WelcomeHeading />
          <CreateMeetingButton />
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="flex gap-6">
              <div className="w-full p-6 bg-white rounded-xl shadow border border-[#eaecf0] flex-col justify-center items-start gap-2 inline-flex">
                <div className="w-10 h-10 p-2.5 bg-black rounded-lg justify-center items-center inline-flex">

                  <svg width="20" height="20" className="w-5 h-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="file-lock-02">
                      <path id="Fill" opacity="0.12" d="M11.6665 15.5001C11.6665 15.0334 11.6665 14.8 11.7573 14.6218C11.8372 14.465 11.9647 14.3375 12.1215 14.2576C12.2998 14.1667 12.5331 14.1667 12.9998 14.1667H16.1665C16.6332 14.1667 16.8666 14.1667 17.0448 14.2576C17.2016 14.3375 17.3291 14.465 17.409 14.6218C17.4998 14.8 17.4998 15.0334 17.4998 15.5001V16.1667C17.4998 16.6335 17.4998 16.8668 17.409 17.0451C17.3291 17.2019 17.2016 17.3294 17.0448 17.4093C16.8666 17.5001 16.6332 17.5001 16.1665 17.5001H12.9998C12.5331 17.5001 12.2998 17.5001 12.1215 17.4093C11.9647 17.3294 11.8372 17.2019 11.7573 17.0451C11.6665 16.8668 11.6665 16.6335 11.6665 16.1667V15.5001Z" fill="black" />
                      <path id="Icon" d="M16.6668 8.33342V5.66675C16.6668 4.26662 16.6668 3.56655 16.3943 3.03177C16.1547 2.56137 15.7722 2.17892 15.3018 1.93923C14.767 1.66675 14.067 1.66675 12.6668 1.66675H7.3335C5.93336 1.66675 5.2333 1.66675 4.69852 1.93923C4.22811 2.17892 3.84566 2.56137 3.60598 3.03177C3.3335 3.56655 3.3335 4.26662 3.3335 5.66675V14.3334C3.3335 15.7335 3.3335 16.4336 3.60598 16.9684C3.84566 17.4388 4.22811 17.8212 4.69852 18.0609C5.2333 18.3334 5.93336 18.3334 7.3335 18.3334H8.75016M10.8335 9.16675H6.66683M9.16683 12.5001H6.66683M13.3335 5.83341H6.66683M16.0418 14.1667V12.7084C16.0418 11.903 15.3889 11.2501 14.5835 11.2501C13.7781 11.2501 13.1252 11.903 13.1252 12.7084V14.1667M13.0002 17.5001H16.1668C16.6335 17.5001 16.8669 17.5001 17.0452 17.4093C17.202 17.3294 17.3294 17.2019 17.4093 17.0451C17.5002 16.8668 17.5002 16.6335 17.5002 16.1667V15.5001C17.5002 15.0334 17.5002 14.8 17.4093 14.6218C17.3294 14.465 17.202 14.3375 17.0452 14.2576C16.8669 14.1667 16.6335 14.1667 16.1668 14.1667H13.0002C12.5335 14.1667 12.3001 14.1667 12.1218 14.2576C11.965 14.3375 11.8376 14.465 11.7577 14.6218C11.6668 14.8 11.6668 15.0334 11.6668 15.5001V16.1667C11.6668 16.6335 11.6668 16.8668 11.7577 17.0451C11.8376 17.2019 11.965 17.3294 12.1218 17.4093C12.3001 17.5001 12.5335 17.5001 13.0002 17.5001Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                </div>
                <p className="self-stretch text-black text-sm font-medium font-['Inter'] leading-tight">Active Briefs</p>
                <p className="grow shrink basis-0 text-black text-3xl font-semibold leading-[38px]">
                  {stats?.activeBriefs}
                </p>
              </div>
              <div className="w-full p-6 bg-white rounded-xl shadow border border-[#eaecf0] flex-col justify-center items-start gap-2 inline-flex">
                <div className="w-10 h-10 p-2.5 bg-black rounded-lg justify-center items-center inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="file-question-02">
                      <path id="Icon" d="M16.6668 7.91675V5.66675C16.6668 4.26662 16.6668 3.56655 16.3943 3.03177C16.1547 2.56137 15.7722 2.17892 15.3018 1.93923C14.767 1.66675 14.067 1.66675 12.6668 1.66675H7.3335C5.93336 1.66675 5.2333 1.66675 4.69852 1.93923C4.22811 2.17892 3.84566 2.56137 3.60598 3.03177C3.3335 3.56655 3.3335 4.26662 3.3335 5.66675V14.3334C3.3335 15.7335 3.3335 16.4336 3.60598 16.9684C3.84566 17.4388 4.22811 17.8212 4.69852 18.0609C5.2333 18.3334 5.93336 18.3334 7.3335 18.3334H11.6668M11.6668 9.16675H6.66683M8.3335 12.5001H6.66683M13.3335 5.83341H6.66683M13.7502 12.5019C13.897 12.0845 14.1868 11.7326 14.5683 11.5084C14.9497 11.2842 15.3982 11.2023 15.8343 11.2771C16.2704 11.3519 16.666 11.5786 16.9509 11.9171C17.2359 12.2556 17.3919 12.684 17.3912 13.1265C17.3912 14.3755 15.5176 15.0001 15.5176 15.0001M15.5417 17.5001H15.5501" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                </div>
                <p className="self-stretch text-black text-sm font-medium font-['Inter'] leading-tight">Pending Approval</p>
                <p className="grow shrink basis-0 text-black text-3xl font-semibold leading-[38px]">
                  {stats?.pendingApprovals}
                </p>
              </div>
            </div>
            <MeetingHistory />
          </div>
          <UpcomingMeetings />
        </div>
      </div>
    </Layout>
  );
}

export default DashboardIndex;
