import axios from 'axios';
import Config from '../config';

const getContactWithMeetingHistory = (id, page) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/contact/${id}?page=${page || 1}&limit=10`,
    data: {},
  });
};

export default getContactWithMeetingHistory;
