import { Link } from 'react-router-dom';
import { Progress } from '../../../components/ui/progress';

function Package() {
  return (
    <div className="bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start inline-flex w-full">
      <div className="p-6 flex-col justify-start items-start gap-6 flex w-full">
        <div className="gap-8 flex justify-between w-full">
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="items-center flex gap-3">
              <div className="text-gray-900 text-lg font-semibold leading-7">Basic plan</div>
              <p className="bg-white rounded-full border text-zinc-800 text-sm font-medium leading-tight px-3 py-1 m-0">Monthly</p>
            </div>
            <div className="text-slate-600 text-sm font-normal leading-tight">Our most popular plan for small teams.</div>
          </div>
          <div className="flex items-end gap-0.5">
            <h4 className="flex justify-start items-end gap-2.5">
              <div className="text-gray-900 text-4xl font-semibold leading-[44px]">$10</div>
              {' '}
              <span className="text-slate-600 text-base font-medium leading-normal">per month</span>
            </h4>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-3 flex w-full">
          <div className="text-gray-900 text-sm font-medium leading-tight">14 of 20 users</div>
          <Progress value={33} className="h-2" />
        </div>
      </div>
      <hr className="w-full m-0" />
      <div className="p-4 justify-end w-full flex">
        <Link to="/" className="flex gap-2">
          <div className="text-zinc-800 text-sm font-semibold leading-tight">Upgrade plan</div>
          <img src="/image/icons/arrow-up-right.svg" alt="" className="h-6 w-6" />
        </Link>
      </div>
    </div>
  );
}

export default Package;
