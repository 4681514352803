import axios from 'axios';
import Config from '../config';

const changePassword = (password, newPassword, token) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/auth/reset-password`,
    data: { password, newPassword, token },
  });
};

export default changePassword;
