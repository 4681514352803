/* eslint-disable indent */
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,
} from '../../../components/ui/select';

function getTimezoneOffsetWithName(timezone) {
    const now = moment().tz(timezone);
    const gmtOffset = now.format('Z');
    return `${timezone} GMT${gmtOffset}`;
}

function TimezoneSelector({ setSelectedTimezone = () => {} }) {
    const [data, setData] = useState([]);
    const [currentTimezone, setCurrentTimezone] = useState('');
    useEffect(() => {
        fetch('https://raw.githubusercontent.com/solaiman5683/timezone/main/timezone.json')
            .then((res) => res.json())
            .then((data) => {
                const newData = data.map((item) => getTimezoneOffsetWithName(item));
                // console.log(newData);
                setData(newData);
            });
        const currentZone = moment.tz.guess();
      setCurrentTimezone(getTimezoneOffsetWithName(currentZone));
    }, []);

  useEffect(() => {
    if (currentTimezone) {
      setSelectedTimezone(currentTimezone);
    }
  }, [currentTimezone]);

  return (
    <Select value={currentTimezone} onValueChange={(value) => setCurrentTimezone(value)}>
      <SelectTrigger className="min-w-[150px] max-w-[300px] bg-transparent p-0 py-0 border-none h-auto focus:ring-0 focus:bg-transparent focus:shadow-none focus:border-none ring-offset-0 focus:ring-offset-0">
        <SelectValue placeholder="Select Timezone" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {
                    data.map((item) => <SelectItem key={item} value={item}>{item}</SelectItem>)
                  }
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default TimezoneSelector;
