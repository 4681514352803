import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import setAuthToken from '../../../actions/setAuthToken';
import SideNavigation from './side-navigation';

function Sidebar() {
  const setToken = useGlobal('token')[1];
  const setUser = useGlobal('user')[1];

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <div className="w-[250px] xl:w-[300px] bg-white h-screen border border-gray-300 px-6 py-8 space-y-6 flex flex-col overflow-auto scroll-none">
      <Link to="/">
        {/* <Logo /> */}
        <img src="/logo.svg" className="" width="186px" alt="" />
      </Link>

      <div className="flex-grow min-h-[200px] overflow-y-scroll scroll-none">
        <SideNavigation />
      </div>

      {/* <SideNavAlert /> */}
      <div>
        <hr className="mb-2" />
        {/* <LogoutBtn /> */}
        <button onClick={handleLogout} className="w-full rounded-md justify-center items-center gap-3 flex bg-gray-100 py-3">
          <span className="text-base font-semibold leading-normal">
            Logout
          </span>
          <img src="/image/icons/log-out.svg" width={16} height={16} alt="" />
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
