import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import App from './App';
import './index.css';
import init from './init';
import * as serviceWorker from './serviceWorker';
import store from './store';

init().then(() => {
  // Loading UIkit Icons plugin.
  UIkit.use(Icons);

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <GoogleOAuthProvider clientId="454767405228-9efn7n0tb45i1i0eht116t8gukjd2hck.apps.googleusercontent.com">
      <React.StrictMode>
        <Provider store={store}>
          <ToastProvider autoDismiss autoDismissTimeout={6000} placement="top-right">
            <App />
          </ToastProvider>
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
