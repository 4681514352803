import { NavLink } from 'react-router-dom';

const dashboardNavItems = [
  {
    label: 'Dashboard',
    href: '/',
    icon: '/image/icons/layer.svg',
  },
  {
    label: 'My Briefs',
    href: '/my-briefs',
    icon: '/image/icons/brief.svg',
  },
  {
    label: 'Contact',
    href: '/contact',
    icon: '/image/icons/users.svg',
  },
  {
    label: 'Back Office',
    href: '/back-office',
    icon: '/image/icons/back_office.svg',
  },
  // {
  //   label: 'Resources',
  //   href: '/resources',
  //   icon: '/image/icons/folder.svg',
  // },
  {
    label: 'Billing',
    href: '/billing',
    icon: '/image/icons/wallet.svg',
  },
  {
    label: 'Settings',
    href: '/settings',
    icon: '/image/icons/settings.svg',
  },
  {
    label: 'Support',
    href: '/support',
    icon: '/image/icons/message-smile-circle.svg',
  },
];
function SideNavigation() {
  return (
    <ul className="flex flex-col gap-1 m-0 p-0">
      {/* {dashboardNavItems.map(item => <></>)} */}
      {dashboardNavItems.map((item, i) => (
        <li
          key={i}
        //   className={cn(
        //     'py-2 px-4 rounded-lg text-slate-700 hover:bg-gray-100',
        //     path === item.href && 'bg-gray-100 text-gray-900',
        //   )}
        >
          <NavLink
            to={`${item.href}`}
            className={({ isActive }) => `py-2 px-4 rounded-lg text-slate-700 hover:bg-gray-50 hover:text-gray-900 ${isActive && 'bg-gray-50 text-gray-900'} justify-start items-center gap-3 flex`}
          >
            <img src={item.icon} width={24} height={24} alt={item.label} />
            <span className="text-base font-semibold leading-normal">
              {item.label}
            </span>
          </NavLink>
        </li>
      ))}

    </ul>
  );
}

export default SideNavigation;
