/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-undef */
/* eslint-disable indent */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
// import { Strikethrough, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
// eslint-disable-next-line
import html2pdf from 'html2pdf.js';
import { marked } from 'marked';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import TurndownService from 'turndown';
import { Button } from '../../../components/ui/button';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import Config from '../../../config';
import Layout from '../layout';

const getBriefData = async (id) => {
  try {
    const res = await axios.get(`${Config.url || ''}/api/v1/user/brief/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

function replaceUserIdWithNames(transcript, users) {
  return transcript.replace(/user_(\w+)_([\d-:.TZ]+)/g, (match, userId, timestamp, index) => {
    const user = users.find((u) => u._id === userId);
    if (user) {
      const formattedTime = timestamp?.replace('Z:', '') && new Date(timestamp?.replace('Z:', '') || '')?.toLocaleString(); // Format timestamp if needed
      return `${index !== 2 ? '<br/><br/>' : ''}<strong class="text-[#101828] font-medium leading-7">${user?.firstName} [${formattedTime}] :</strong>`;
    }
    return match; // If user ID not found, return the original match
  });
}

function formatTranscripts(text, users) {
  try {
    if (!text && !users) {
      return '';
    }
    const textArray = text.split('user_').filter((item) => item !== '\n ');
    // console.log(textArray, 'input');
    const formattedTranscript = textArray?.map((item) => {
      const userID = item.slice(0, item.indexOf('_'));
      const user = users?.find((item) => item._id === userID);
      // console.log(user);
      const [timestamp, trans] = item.replace(`${userID}_`, '').split(': ');
      let formattedTimestamp = '00:00';
      if (timestamp) {
        formattedTimestamp = new Date(timestamp || '')?.toISOString()?.substr(11, 8);
      }
      // return item;
      return `<p class="text-[#475467] text-base font-normal leading-normal mb-5">
        <strong class="text-[#101828] font-medium leading-7">${user?.firstName} [${formattedTimestamp}] :</strong> ${trans && capitalizeFirstLetter(trans?.trim())}.
      </p>`;
    }).join(' ');

    return formattedTranscript;
  } catch (error) {
    console.log(error);
    return '';
  }
}

function BriefPage() {
  const [user] = useGlobal('user');
  const editorRef = useRef(null);
  const { id } = useParams();
  const io = useSelector((state) => state.io.io);

  const [step, setStep] = useState(1);
  const [transcript, setTranscript] = useState('');
  const [meeting, setMeeting] = useState(null);

  // Brief
  const [brief, setBrief] = useState('');
  const [briefStatus, setBriefStatus] = useState('');
  const [briefId, setBriefId] = useState('');
  const [briefObject, setBriefObject] = useState('');

  const [status, setStatus] = useState('');
  const [isGenerateBrief, setIsGenerateBrief] = useState(false);
  const [briefShouldSave, setBriefShouldSave] = useState(false);
  const [isBriefUpdateLoading, setIsBriefUpdateLoading] = useState(false);
  const [isBriefApproveLoading, setIsBriefApproveLoading] = useState(false);
  const [isInvitingLoading, setIsInvitingLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [hostUser, setHostUser] = useState('');

  const navigate = useNavigate();

  const renderedTranscriptHTML = replaceUserIdWithNames(transcript, users);
  const renderedBriefHTML = marked(brief);

  useEffect(() => {
    if (id) {
      const getData = async () => {
        const data = await getBriefData(id);
        if (data.success) {
          if (data?.data.meeting?.transcriptions) {
            setTranscript(data?.data.meeting?.transcriptions);
            setUsers(data?.data.meeting.users);
            setHostUser(data?.data.meeting.createdBy?.toString());
            setMeeting(data?.data.meeting);
          } else {
            toast.error('No transcriptions found', {
              position: 'top-center',
              autoClose: 5000,
            });
            // navigate('/');
          }
          if (data?.data?.brief) {
            setBriefId(data?.data?.brief?._id);
            setBrief(data?.data?.brief?.html);
            setBriefStatus(data?.data?.brief?.status);
            setBriefObject(data?.data?.brief);
          }
          if (data?.data.meeting?.status) {
            setStatus(data?.data.meeting?.status);
          }
        } else {
          toast.error(data?.error, {
            position: 'top-center',
            autoClose: 5000,
          });
          navigate('/');
        }
      };
      getData();
    }
  }, [id, status]);

  useEffect(() => {
    if (io) {
      io.on('generated_brief', (data) => {
        if (data.meetingID === id) {
          setStatus('brief_generated');
        }
        // console.log(data, 'generated_brief');
      });
    }
  }, [io]);

  const handleDownloadBrief = () => {
    const content = editorRef.current.getContent();

    const container = document.createElement('div');
    container.innerHTML = content;
    container.appendChild(document.createElement('br'));
    container.appendChild(document.createElement('br'));
    document.body.appendChild(container);

    // Calculate the height based on content
    const height = container.scrollHeight;
    const pageHeight = (height / 90); // Convert px to inches (assuming 90px per inch)

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // margins
      filename: `brief-${id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: [8.5, pageHeight], orientation: 'portrait' },
    };

    html2pdf().from(container).set(opt).save()
      .then(() => {
        document.body.removeChild(container);
      });
  };

  const handleGenerateBrief = () => {
    if (id) {
      setIsGenerateBrief(true);
      axios.post(`${Config.url || ''}/api/v1/user/generate_brief/${id}`)
        .then((data) => {
          console.log(data);
          if (data.data.success) {
            toast.success(data.data.message, {
              position: 'top-center',
              autoClose: 5000,
            });
            setStatus('generating_brief');
          }
        }).catch((err) => {
          // console.log(err);
          toast.error(err.response.data.error, {
            position: 'top-center',
            autoClose: 5000,
          });
        }).finally(() => {
          setIsGenerateBrief(false);
        });
    }
  };

  const handleInviteGuests = () => {
    if (id) {
      setIsInvitingLoading(true);
      axios.get(`${Config.url || ''}/api/v1/user/invite_guests/${id}`)
        .then((data) => {
          if (data.data.success) {
            toast.success(data.data.message, {
              position: 'top-center',
              autoClose: 5000,
            });
          }
          setIsInvitingLoading(false);
        }).catch((err) => {
          // console.log(err);
          toast.error(err.response.data.error, {
            position: 'top-center',
            autoClose: 5000,
          });
          setIsInvitingLoading(false);
        }).finally(() => {
          setIsInvitingLoading(false);
        });
    }
  };

  const handleUpdateBrief = async () => {
    try {
      setIsBriefUpdateLoading(true);
      const htmlContent = editorRef.current.getContent();
      const turndownService = new TurndownService();
      const markdownContent = turndownService.turndown(htmlContent);
      const { data } = await axios.post(`${Config.url || ''}/api/v1/user/update_brief/${briefId}/${id}`, {
        html: markdownContent,
        score: 99,
      });
      if (data.success) {
        toast.success('Brief updated successfully', {
          position: 'top-center',
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsBriefUpdateLoading(false);
    }
  };

  const handleRequestSentForApprove = async (userId) => {
    try {
      setIsBriefApproveLoading(true);
      await handleUpdateBrief();
      const { data } = await axios.post(`${Config.url || ''}/api/v1/user/request_approval/${briefId}/${id}`, {
        requestedUsers: [userId],
      });

      console.log(data);
      if (data.success) {
        toast.success('Request sent successfully', {
          position: 'top-center',
        });
        setBriefShouldSave(false);
        setStep(step + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsBriefApproveLoading(false);
    }
  };

  const handleApprove = async (userId) => {
    try {
      setIsBriefUpdateLoading(true);
      await handleUpdateBrief();
      const { data } = await axios.post(`${Config.url || ''}/api/v1/user/approve_brief/${briefId}/${id}`);

      if (data.success) {
        toast.success('Brief approved successfully', {
          position: 'top-center',
        });
        setBriefShouldSave(false);
        setStep(step + 1);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        position: 'top-center',
      });
    } finally {
      setIsBriefUpdateLoading(false);
    }
  };
  return (
    <Layout>
      <div className="container mx-auto flex gap-6">
        <div>
          <ol className="border bg-white rounded-lg p-5 h-[300px] flex flex-col justify-between gap-2">
            <li id="animated" className="flex items-center gap-4">
              <span className={`flex items-center justify-center w-10 h-10 bg-white border rounded-xl lg:h-12 lg:w-12 shrink-0 ${step > 0 ? 'text-slate-700' : 'text-[#D0D5DD]'} relative z-50`}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="type-02">
                    <path id="Icon" d="M3.66699 5.83325C3.66699 5.05668 3.66699 4.6684 3.79386 4.36211C3.96302 3.95373 4.28747 3.62928 4.69585 3.46012C5.00214 3.33325 5.39042 3.33325 6.16699 3.33325H14.5003C15.2769 3.33325 15.6652 3.33325 15.9715 3.46012C16.3798 3.62928 16.7043 3.95373 16.8735 4.36211C17.0003 4.6684 17.0003 5.05668 17.0003 5.83325M7.00033 16.6666H13.667M8.87533 3.33325V16.6666M11.792 3.33325V16.6666" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </span>
              <span>Transcript</span>
            </li>
            <div className={`flex-grow w-0.5 bg-[#eaecf0] rounded-full ml-6 relative after:content-[' '] after:absolute after:top-0 after:left-0 ${step > 1 ? 'after:h-full' : 'after:h-0'} after:w-full after:bg-[#101828] after:transition-all after:duration-300 after:ease-in-out`} />
            <li id="animated" className="flex items-center gap-4">
              <span className={`flex items-center justify-center w-10 h-10 bg-white border rounded-xl lg:h-12 lg:w-12 shrink-0 ${step > 1 ? 'text-slate-700' : 'text-[#D0D5DD]'} relative z-50`}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="paragraph-wrap">
                    <g id="Solid">
                      <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H3Z" fill="currentColor" />
                      <path d="M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H18C18.5304 13 19.0391 13.2107 19.4142 13.5858C19.7893 13.9609 20 14.4696 20 15C20 15.5304 19.7893 16.0391 19.4142 16.4142C19.0391 16.7893 18.5304 17 18 17H16.4142L16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929C16.3166 14.9024 15.6834 14.9024 15.2929 15.2929L13.2929 17.2929C12.9024 17.6834 12.9024 18.3166 13.2929 18.7071L15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071C17.0976 20.3166 17.0976 19.6834 16.7071 19.2929L16.4142 19H18C19.0609 19 20.0783 18.5786 20.8284 17.8284C21.5786 17.0783 22 16.0609 22 15C22 13.9391 21.5786 12.9217 20.8284 12.1716C20.0783 11.4214 19.0609 11 18 11H3Z" fill="currentColor" />
                      <path d="M3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H10C10.5523 19 11 18.5523 11 18C11 17.4477 10.5523 17 10 17H3Z" fill="currentColor" />
                    </g>
                  </g>
                </svg>
              </span>
              <span>Brief</span>
            </li>
            <div className={`flex-grow w-0.5 bg-[#eaecf0] rounded-full ml-6 relative after:content-[' '] after:absolute after:top-0 after:left-0 ${step > 2 ? 'after:h-full' : 'after:h-0'} after:w-full after:bg-[#101828] after:transition-all after:duration-300 after:ease-in-out`} />
            <li className="flex items-center gap-4">
              <span className={`flex items-center justify-center w-10 h-10 bg-white border rounded-xl lg:h-12 lg:w-12 shrink-0 ${step > 2 ? 'text-slate-700' : 'text-[#D0D5DD]'}`}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="briefcase-02">
                    <path id="Icon" d="M7.3335 17.5V5.83333C7.3335 5.05836 7.3335 4.67087 7.41868 4.35295C7.64985 3.49022 8.32372 2.81635 9.18645 2.58519C9.50437 2.5 9.89185 2.5 10.6668 2.5C11.4418 2.5 11.8293 2.5 12.1472 2.58519C13.0099 2.81635 13.6838 3.49022 13.915 4.35295C14.0002 4.67087 14.0002 5.05836 14.0002 5.83333V17.5M5.00016 17.5H16.3335C17.2669 17.5 17.7336 17.5 18.0901 17.3183C18.4038 17.1586 18.6587 16.9036 18.8185 16.59C19.0002 16.2335 19.0002 15.7668 19.0002 14.8333V8.5C19.0002 7.56658 19.0002 7.09987 18.8185 6.74335C18.6587 6.42975 18.4038 6.17478 18.0901 6.01499C17.7336 5.83333 17.2669 5.83333 16.3335 5.83333H5.00016C4.06674 5.83333 3.60003 5.83333 3.24351 6.01499C2.92991 6.17478 2.67494 6.42975 2.51515 6.74335C2.3335 7.09987 2.3335 7.56658 2.3335 8.5V14.8333C2.3335 15.7668 2.3335 16.2335 2.51515 16.59C2.67494 16.9036 2.92991 17.1586 3.24351 17.3183C3.60003 17.5 4.06674 17.5 5.00016 17.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </span>
              <span>Approval</span>
            </li>
          </ol>
        </div>

        <div className="space-y-6 w-full">
          <div>
            {step === 1 && (
              <div className="bg-white border-2 rounded p-4 overflow-scroll scroll-none max-h-[600px] space-y-3 w-full">
                <div dangerouslySetInnerHTML={{ __html: renderedTranscriptHTML }} />
              </div>
            )}
            <div className={`${step === 2 ? 'opacity-100 w-full' : 'opacity-0 hidden'}`}>
              <Editor
                apiKey="qvctqtfhdqwqkjf8r0rd2dbjuk44fzk70v0sosx67u0z5msk"
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={renderedBriefHTML}
                onEditorChange={() => {
                  setBriefShouldSave(true);
                }}
                disabled={hostUser !== user?._id || !briefObject?.requestedUsers?.includes(user?._id.toString())}
                className="w-full"
                init={{
                  height: 600,
                  width: '100%',
                  menubar: false,
                  toolbar: 'blocks formatselect | bold italic link image | bullist numlist | undo redo lists | code',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
                plugins="advlist autolink lists link image insertdatetime visualchars media table paste code help wordcount visualblocks"
              />
            </div>
            {
              step === 3 && (
                <div>
                  <Editor
                    apiKey="qvctqtfhdqwqkjf8r0rd2dbjuk44fzk70v0sosx67u0z5msk"
                    // onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={editorRef.current.getContent()}
                    init={{
                      height: 600,
                      menubar: false,
                      toolbar: false,
                      // toolbar: 'blocks formatselect | bold italic link image | bullist numlist | undo redo lists | code',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    plugins="advlist autolink lists link image insertdatetime visualchars media table paste code help wordcount visualblocks"
                    disabled
                  />
                </div>
              )
            }
          </div>

          <div className="flex gap-4">
            {(step === 1) && (
              status === 'brief_generated'
                ? (
                  <Button
                    onClick={() => setStep((prev) => {
                      if ((hostUser === user._id && briefStatus === 'requested_for_approval') || briefStatus === 'approved') {
                        return prev + 2;
                      }
                      return prev + 1;
                    })}
                    className="flex items-center gap-2"
                  >
                    Proceed to brief
                    {' '}
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="arrows-right">
                        <path id="Icon" d="M4 7H15M15 7L11 11M15 7L11 3M4 17H20M20 17L16 21M20 17L16 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </Button>
                )
                : status === 'generating_brief' ? (
                  <Button className="flex items-center gap-2 cursor-wait bg-gray-500 hover:bg-gray-600">
                    Generating Brief
                    {' '}
                    {/* <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrows-right">
                      <path id="Icon" d="M4 7H15M15 7L11 11M15 7L11 3M4 17H20M20 17L16 21M20 17L16 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg> */}
                    <span className="border-2 border-white rounded-full h-3 w-3 animate-spin border-t-transparent" />
                  </Button>
                ) : (hostUser === user._id?.toString() || hostUser === user.id?.toString()) ? (
                  <Button onClick={handleGenerateBrief} className="flex items-center gap-2">
                    Generate Brief
                    {' '}
                    {
                      isGenerateBrief
                        ? <span className="border-2 border-white rounded-full h-3 w-3 animate-spin border-t-transparent" />
                        : (
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="arrows-right">
                              <path id="Icon" d="M4 7H15M15 7L11 11M15 7L11 3M4 17H20M20 17L16 21M20 17L16 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        )
                    }
                  </Button>
                ) : (
                  <Button disabled className="flex items-center gap-2">
                    Wait for host to generate brief
                    {' '}
                    {
                      isGenerateBrief
                        ? <span className="border-2 border-white rounded-full h-3 w-3 animate-spin border-t-transparent" />
                        : (
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="arrows-right">
                              <path id="Icon" d="M4 7H15M15 7L11 11M15 7L11 3M4 17H20M20 17L16 21M20 17L16 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        )
                    }
                  </Button>
                )
            )}
            {step === 2 && (
              <>
                {
                  ((hostUser === user._id || briefObject?.requestedUsers?.includes(user._id)) && briefStatus !== 'approved') && (
                    <Button onClick={handleUpdateBrief} disabled={!briefShouldSave || isBriefUpdateLoading}>
                      Save
                      {
                        isBriefUpdateLoading && <span className="w-4 h-4 border border-white border-t-gray-600 rounded-full animate-spin ml-1" />
                      }
                    </Button>
                  )
                }

                {
                  (briefObject?.requestedUsers?.includes(user._id) && briefStatus !== 'approved') && (
                    <Button onClick={handleApprove}>
                      Approve
                      {
                        isBriefUpdateLoading && <span className="w-4 h-4 border border-white border-t-gray-600 rounded-full animate-spin ml-1" />
                      }
                    </Button>
                  )
                }

                {/* <Button onClick={() => setStep(step + 1)}>
                  Request for approval
                </Button> */}
                {
                  (hostUser === user._id && briefStatus !== 'requested_for_approval' && briefStatus !== 'approved') && (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button>
                        Request for approval
                        {
                            isBriefApproveLoading && <span className="size-2.5 border-2 border-white border-t-transparent rounded-full ml-1.5 animate-spin" />
                          }
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>
                        Request to approve
                      </DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {
                          users.filter((user) => user._id !== hostUser).map((user, i) => (
                            <DropdownMenuItem onClick={() => handleRequestSentForApprove(user._id)} key={i}>
                              {user.firstName}
                              {' '}
                              {user.lastName}
                              {' '}
                              (@
                              {user.username.split('@')[0]}
                              )
                            </DropdownMenuItem>
                          ))
                        }
                    </DropdownMenuContent>
                  </DropdownMenu>
                  )
                }

              </>
            )}
            {step === 3 && (
              briefObject?.status === 'approved' ? (
                <Button onClick={handleDownloadBrief} variant="outline" className="flex gap-1 items-center">
                  Download Brief
                  {' '}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                </Button>
              ) : (
                <Button disabled className="flex items-center gap-2">
                  Wait for approve brief
                  {' '}
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

                    <g id="arrows-right">
                      <path id="Icon" d="M4 7H15M15 7L11 11M15 7L11 3M4 17H20M20 17L16 21M20 17L16 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                </Button>
              )
            )}
            {(step > 1 && step < 3) && (
              <Button onClick={() => setStep(step - 1)} variant="outline" className="flex gap-1 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                Back
              </Button>
            )}

          </div>
        </div>

        <div>
          <div className="border bg-white p-5 space-y-5">
            <h2 className="text-gray-900 text-xl font-semibold leading-tight m-0">Briefing Team</h2>

            {
              briefObject?.invitationSent && (
                <p> Invitation has been already sent.</p>
              )
            }
            {
            users?.map((user, i) => (
              <div key={i}>
                <div className="flex items-center gap-3">
                  <div className="w-[4rem] h-[3rem] rounded-full overflow-hidden flex items-center justify-center">
                    <img className="w-full h-full object-cover" src={`${
                          user?.picture?.location
                            ? `${Config.url || ''}/${user?.picture?.location}`
                            : '/image/placeholder/avatar.png'
                        }`} alt="Profile"
                        />
                  </div>
                  <div className="flex w-full justify-between gap-1">
                    <h4 className="text-gray-900 text-sm font-semibold leading-tight m-0 min-w-max">
                      {user.firstName}
                      {' '}
                      {user.lastName}
                    </h4>
                    <div className="px-2 py-0.5 bg-gray-50 rounded border border-[#eaecf0] text-center text-[#344054] text-xs font-medium leading-[18px]">
                      {hostUser === user._id ? 'Host' : 'Guest'}
                    </div>
                  </div>
                </div>
                <p className="flex gap-2 text-sm text-slate-600 ml-2 pt-2">
                  {user.email}
                </p>
              </div>
            ))
          }
            <Button onClick={handleInviteGuests} variant="outline" className="border-[#1570EF] text-[#1570EF] hover:text-[#1570EF] flex items-center gap-2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="share-05">
                  <g id="Solid">
                    <path d="M6.46542 1.66675H8.33317C8.79341 1.66675 9.1665 2.03985 9.1665 2.50008C9.1665 2.96032 8.79341 3.33342 8.33317 3.33342H6.49984C5.78602 3.33342 5.30078 3.33406 4.9257 3.36471C4.56036 3.39456 4.37352 3.44866 4.24319 3.51507C3.92958 3.67486 3.67462 3.92983 3.51483 4.24343C3.44842 4.37376 3.39431 4.5606 3.36446 4.92595C3.33382 5.30102 3.33317 5.78627 3.33317 6.50008V13.5001C3.33317 14.2139 3.33382 14.6991 3.36446 15.0742C3.39431 15.4396 3.44842 15.6264 3.51483 15.7567C3.67462 16.0703 3.92958 16.3253 4.24319 16.4851C4.37352 16.5515 4.56036 16.6056 4.9257 16.6355C5.30078 16.6661 5.78602 16.6667 6.49984 16.6667H13.4998C14.2137 16.6667 14.6989 16.6661 15.074 16.6355C15.4393 16.6056 15.6262 16.5515 15.7565 16.4851C16.0701 16.3253 16.3251 16.0703 16.4848 15.7567C16.5513 15.6264 16.6054 15.4396 16.6352 15.0742C16.6659 14.6991 16.6665 14.2139 16.6665 13.5001V11.6667C16.6665 11.2065 17.0396 10.8334 17.4998 10.8334C17.9601 10.8334 18.3332 11.2065 18.3332 11.6667V13.5345C18.3332 14.2053 18.3332 14.7589 18.2963 15.2099C18.2581 15.6784 18.1759 16.1089 17.9699 16.5134C17.6503 17.1406 17.1403 17.6505 16.5131 17.9701C16.1087 18.1762 15.6781 18.2583 15.2097 18.2966C14.7587 18.3334 14.2051 18.3334 13.5343 18.3334H6.4654C5.7946 18.3334 5.24097 18.3334 4.78998 18.2966C4.32157 18.2583 3.89098 18.1762 3.48654 17.9701C2.85933 17.6505 2.34939 17.1406 2.02982 16.5134C1.82374 16.1089 1.7416 15.6784 1.70333 15.2099C1.66649 14.7589 1.66649 14.2053 1.6665 13.5345V6.46567C1.66649 5.79486 1.66649 5.24122 1.70333 4.79023C1.7416 4.32181 1.82374 3.89123 2.02982 3.48678C2.34939 2.85957 2.85933 2.34964 3.48654 2.03006C3.89098 1.82399 4.32157 1.74185 4.78998 1.70358C5.24098 1.66673 5.79462 1.66674 6.46542 1.66675Z" fill="currentColor" />
                    <path d="M15.488 5.83342L14.4106 6.91083C14.0851 7.23626 14.0851 7.7639 14.4106 8.08934C14.736 8.41477 15.2637 8.41477 15.5891 8.08934L18.0891 5.58934C18.4145 5.2639 18.4145 4.73626 18.0891 4.41083L15.5891 1.91083C15.2637 1.58539 14.736 1.58539 14.4106 1.91083C14.0851 2.23626 14.0851 2.7639 14.4106 3.08934L15.488 4.16675L14.7988 4.16675C14.1279 4.16674 13.5743 4.16673 13.1233 4.20358C12.6549 4.24185 12.2243 4.32399 11.8199 4.53006C11.1927 4.84964 10.6827 5.35957 10.3632 5.98678C10.1571 6.39123 10.0749 6.82181 10.0367 7.29023C9.99982 7.74122 9.99983 8.29485 9.99984 8.96564L9.99984 10.0001C9.99984 10.4603 10.3729 10.8334 10.8332 10.8334C11.2934 10.8334 11.6665 10.4603 11.6665 10.0001V9.00008C11.6665 8.28627 11.6672 7.80102 11.6978 7.42595C11.7276 7.0606 11.7818 6.87376 11.8482 6.74343C12.0079 6.42983 12.2629 6.17486 12.5765 6.01507C12.7069 5.94866 12.8937 5.89456 13.259 5.86471C13.6341 5.83406 14.1194 5.83342 14.8332 5.83342H15.488Z" fill="currentColor" />
                  </g>
                </g>
              </svg>
              {
              isInvitingLoading ? 'Inviting...' : `${briefObject?.invitationSent ? 'Again' : ''} Invite`
             }
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BriefPage;
