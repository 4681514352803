import { useEffect } from 'react';

/* eslint-disable no-unused-vars */
const filters = [
  {
    label: 'Active',
    value: 'approved',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];
function BriefFilter({
  setSelectedFilter, selectedFilter,
}) {
  useEffect(() => {
    setSelectedFilter(filters[0].value);
  }, []);
  return (
    <div className="rounded-lg shadow-sm overflow-hidden border border-[#d0d5dd] flex">
      {
        filters.map((filter, i) => (
          <div key={i} onClick={() => setSelectedFilter(filter.value)} className={`px-4 py-2 ${selectedFilter === filter.value ? 'bg-[d0d5dd]' : 'bg-gray-50'} ${i < filters.length - 1 && 'border-r'} border-[#d0d5dd] justify-center items-center gap-2 flex cursor-pointer `}>
            {
              filter.value === selectedFilter && (
                <div className="size-2 bg-[#17b169] rounded-full" />
              )
            }
            <p className="text-[#344054] text-sm font-semibold leading-tight">
              {filter.label}
            </p>
          </div>
        ))
      }
      {/* <div className="px-4 py-2 bg-gray-50 border-r border-[#d0d5dd] justify-center items-center gap-2 flex cursor-pointer">
        <div className="w-2 h-2 bg-[#17b169] rounded-full" />
        <p className="text-[#344054] text-sm font-semibold leading-tight">Active </p>
      </div>
      <div className="px-4 py-2 bg-white border-r border-[#d0d5dd] justify-center items-center gap-2 flex cursor-pointer">
        <p className="text-[#344054] text-sm font-semibold leading-tight">Pending</p>
      </div>
      <div className="px-4 py-2 bg-white border-r border-[#d0d5dd] justify-center items-center gap-2 flex cursor-pointer">
        <p className="text-[#344054] text-sm font-semibold leading-tight">Archived</p>
      </div> */}
    </div>
  );
}

export default BriefFilter;
