import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGlobal } from 'reactn';
import getRoom from '../../actions/getRoom';
import Actions from '../../constants/Actions';
import './Conversation.sass';
import BottomBar from './components/BottomBar';
import Messages from './components/Messages';
import TopBar from './components/TopBar';

function Conversation({ id, closeChat, chatRoomLoading }) {
  const room = useSelector((state) => state.io.room);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const setOver = useGlobal('over')[1];
  // const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const back = () => {
    setOver(false);
    navigate('/', { replace: true });
  };

  useEffect(() => {
    setLoading(true);
    getRoom(id)
      .then((res) => {
        dispatch({ type: Actions.SET_ROOM, room: res.data.room });
        dispatch({ type: Actions.SET_MESSAGES, messages: res.data.room.messages });
        setLoading(false);
        setError(false);
        dispatch({ type: Actions.MESSAGES_REMOVE_ROOM_UNREAD, roomID: id });
      })
      .catch((err) => {
        dispatch({ type: Actions.SET_ROOM, room: null });
        dispatch({ type: Actions.SET_MESSAGES, messages: [] });
        setLoading(false);
        if (!err.response || err.response.status !== 404) setError(true);
      });
  }, [setLoading, id]);

  function Loading() {
    return (
      <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <ClipLoader size={60} color="#666" loading={loading} />
      </div>
    );
  }

  function NotFound() {
    return (
      <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column space-y-3">
        <div className="text-2xl font-bold text-gray-500">Room Not Found</div>
        <div className="text-center text-sm">
          This room does not exist.
          <br />
          This is probably a broken URL.
        </div>
      </div>
    );
  }

  function Error() {
    return (
      <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <div className="notfound">Network Error</div>
        <div className="notfound-extended">Could not reach server.</div>
      </div>
    );
  }

  function Content() {
    return <Messages />;
  }

  return (
    <div className="flex flex-col justify-between h-full w-full space-y-6">
      <TopBar closeChat={closeChat} back={back} loading={loading} />
      {(loading || chatRoomLoading) && <Loading />}
      {error && <Error />}
      {!room && !loading && !error && !chatRoomLoading && <NotFound />}
      {room && !loading && <Content />}
      <BottomBar disabled={!room && !loading && !error && !chatRoomLoading} />
    </div>
  );
}

export default Conversation;
