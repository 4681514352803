/* eslint-disable no-unused-vars */
import { useState } from 'react';

function PreviewSelector() {
  const [mode, setMode] = useState('desktop');
  return (
    <div className="flex items-center justify-between">
      <h5 className="text-[#101828] text-base font-semibold leading-normal">Preview for public url</h5>
      <div className="px-2 py-1.5 rounded-full border-2 border-slate-300 justify-end items-center flex">
        <button onClick={() => setMode('phone')} className={`w-12 h-8 px-4 py-2 ${mode === 'phone' && 'bg-slate-200'} rounded-full justify-center items-center inline-flex`}>
          <img className="w-4 h-4" src="/image/icons/phone.svg" alt="" />
        </button>
        <button onClick={() => setMode('desktop')} className={`w-12 h-8 px-4 py-2 ${mode === 'desktop' && 'bg-slate-200'} rounded-full justify-center items-center inline-flex`}>
          <img className="w-4 h-4" src="/image/icons/desktop.svg" alt="" />
        </button>
      </div>
    </div>
  );
}

export default PreviewSelector;
