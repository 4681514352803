import axios from 'axios';
import Config from '../config';

const getMeetingHistory = (page, limit = 10, filter) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/meetings_history?page=${page || 1}&limit=${limit}${filter ? `&${filter}` : ''}`,
  });
};

export default getMeetingHistory;
