/* eslint-disable indent */

import { useEffect, useState } from 'react';
import getNotificationCount from '../../../actions/getNotificationCount';
import getNotifications from '../../../actions/getNotifications';
import markAsRead from '../../../actions/markAsRead';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';

function getHourMinuteDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMilliseconds = Math.abs(date2 - date1);
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes}m`;
}

export default function NotificationButton() {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [refreshNotifications, setRefreshNotifications] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: notificationsCount } = await getNotificationCount();
        const { data: notificationList } = await getNotifications();
        setNotificationCount(notificationsCount?.data || 0);
        setNotifications(notificationList?.data || []);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    getData();
  }, [refreshNotifications]);

  const handleMarkAsRead = async (id) => {
    console.log(id);
    try {
      const { data } = await markAsRead(id);
      if (data.success) {
        console.log(`Notification ${id} marked as read at (${data.data.readAt})`);
        setRefreshNotifications((prevState) => !prevState);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="relative">
          <img src="/image/icons/bell.svg" alt="" className="w-6 h-6" />
          {
            notificationCount > 0 && <div className="absolute flex items-center justify-center h-4 min-w-4 rounded-full p-1 text-xs top-0 -right-2 bg-[#FFED74] font-bold">{notificationCount}</div>
          }
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[400px]">
        <DropdownMenuLabel className="flex items-center gap-1">
          <img src="/image/icons/bell.svg" alt="" className="size-4" />
          Notifications
          {' '}
          <span>
            (
            {notificationCount}
            )
          </span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <div className="max-h-[300px] overflow-y-auto overflow-x-hidden">
          {
            notifications?.map((notification, i) => (
              <div key={i}>
                <DropdownMenuItem className="flex items-center gap-2">
                  <div>
                    <div className="p-2 rounded-full size-16 bg-gray-100 border flex items-center justify-center">
                      <img src="/image/icons/bell.svg" alt="" className="size-6 opacity-70" />
                    </div>
                  </div>
                  <div>
                    <h6 className="font-semibold text-sm">
                      {notification.title}
                    </h6>
                    <p className="text-gray-400 text-xs mb-1">
                      {notification.message}
                    </p>
                    <small className="text-gray-400">
                      {getHourMinuteDifference(new Date(), notification.createdAt)}
                    </small>
                  </div>
                  {
                    notification.readAt === 'false' && (
                      <button className="ml-auto" title="Mark as read" onClick={() => handleMarkAsRead(notification._id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                        </svg>
                      </button>
                    )
                  }

                </DropdownMenuItem>
                {i !== (notificationCount - 1) && <DropdownMenuSeparator />}
              </div>
            ))
          }

          {/* <DropdownMenuItem className="flex items-center gap-2">
              <div>
                <div className="p-2 rounded-full size-16 bg-gray-100 border flex items-center justify-center">
                  <img src="/image/icons/bell.svg" alt="" className="size-6 opacity-70" />
                </div>
              </div>
              <div>
                <h6 className="font-semibold text-sm">
                  New Meeting Scheduled!
                </h6>
                <p className="text-gray-400 text-xs mb-1">
                  new meeting scheduled between Shimul Sarkar and Shakil Ahmed
                </p>
                <small className="text-gray-400">
                  2h
                </small>
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="flex items-center gap-2">
              <div>
                <div className="p-2 rounded-full size-16 bg-gray-100 border flex items-center justify-center">
                  <img src="/image/icons/bell.svg" alt="" className="size-6 opacity-70" />
                </div>
              </div>
              <div>
                <h6 className="font-semibold text-sm">
                  New Meeting Scheduled!
                </h6>
                <p className="text-gray-400 text-xs mb-1">
                  new meeting scheduled between Shimul Sarkar and Shakil Ahmed
                </p>
                <small className="text-gray-400">
                  2h
                </small>
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="flex items-center gap-2">
              <div>
                <div className="p-2 rounded-full size-16 bg-gray-100 border flex items-center justify-center">
                  <img src="/image/icons/bell.svg" alt="" className="size-6 opacity-70" />
                </div>
              </div>
              <div>
                <h6 className="font-semibold text-sm">
                  New Meeting Scheduled!
                </h6>
                <p className="text-gray-400 text-xs mb-1">
                  new meeting scheduled between Shimul Sarkar and Shakil Ahmed
                </p>
                <small className="text-gray-400">
                  2h
                </small>
              </div>
            </DropdownMenuItem> */}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
