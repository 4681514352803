import axios from 'axios';
import Config from '../config';

const getContacts = (page) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/contacts?page=${page || 1}&limit=10`,
    data: {},
  });
};

export default getContacts;
