/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { toast } from 'sonner';
import { FiPlus, FiSettings } from 'react-icons/fi';
import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import Config from '../../../config';
import { cn, formatDateString } from '../../../lib/utils';
import GuestUserForm from '../pages/back-office/components/guest-user-form';
import Scheduler from '../pages/back-office/components/scheduler';
import FormBuilderForMeeting from './form-builder';
import MeetingScheduleSuccessModalUser from './meeting-schedule-success-modal-user';

function CreateMeetingButton({ className }) {
  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [date, setDate] = useState(new Date());

  const [loading, setLoading] = useState(true);
  const [scheduleId, setScheduleId] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [bookings, setBookings] = useState('');
  const [meetingId, setMeetingId] = useState('');

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const [isEditFormModalOpen, setIsEditFormModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSchedule = () => {
    if (!selectedTime) {
      toast.error('Please select a time.', {
        position: 'top-center',
      });
      return;
    }

    const formData = new FormData();
    formData.append('date', formatDateString(date));
    formData.append('time', selectedTime);
    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        // console.log(data?.data?.message);
        const bookingId = data?.data?.data?._id;
        const meetingId = data?.data?.data?.meeting;
        setBookingId(bookingId);
        setMeetingId(meetingId);
        toast.success(data?.data?.message, {
          position: 'top-center',
        });

        setStep(1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.url || ''}/api/v1/user/book_schedule/get_bookings_with_schedule`)
      .then((data) => {
        const schedule = data?.data?.data?.schedule;
        const bookings = data?.data?.data?.bookings;
        setScheduleId(schedule?._id);
        setDaysOfWeek(schedule?.availableWeekdays);
        setBookings(bookings);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isOpen]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.url || ''}/api/v1/user/book_schedule/get_forms`)
      .then((data) => {
        const forms = data?.data?.data;
        // console.log(forms);
        setForms(forms);
        setSelectedForm(forms[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isOpen]);

  const handleTriggerEditFormModal = (formData) => {
    setSelectedForm(formData);
    if (isOpen) {
      setIsOpen((prev) => !prev);
      setIsEditFormModalOpen((prev) => !prev);
    } else {
      setIsEditFormModalOpen((prev) => !prev);
      setIsOpen((prev) => !prev);
    }
  };

  const handleSaveAndInvite = () => {
    if (!selectedForm) {
      addToast('Please select a Form', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('formId', selectedForm?._id);
    formData.append('bookingId', bookingId);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/save_n_invite`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        if (data?.data?.success) {
          setIsOpen(false);

          toast.success(data?.data?.message, {
            position: 'top-center',
          });

          // Reset Everything
          setStep(0);
          setSelectedTime('');
          setDaysOfWeek([]);
          setForms([]);
          setSelectedForm(null);
          setDate(new Date());
          setShowSuccessModal(true);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger>
          <Button className={cn('flex items-center gap-2 bg-black text-white', className)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="plus-circle">
                <path
                  id="Solid"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0007 0.833252C4.93804 0.833252 0.833984 4.93731 0.833984 9.99992C0.833984 15.0625 4.93804 19.1666 10.0007 19.1666C15.0633 19.1666 19.1673 15.0625 19.1673 9.99992C19.1673 4.93731 15.0633 0.833252 10.0007 0.833252ZM10.0007 5.83325C10.4609 5.83325 10.834 6.20635 10.834 6.66658V9.16658H13.334C13.7942 9.16658 14.1673 9.53968 14.1673 9.99992C14.1673 10.4602 13.7942 10.8333 13.334 10.8333H10.834V13.3333C10.834 13.7935 10.4609 14.1666 10.0007 14.1666C9.54041 14.1666 9.16732 13.7935 9.16732 13.3333V10.8333H6.66732C6.20708 10.8333 5.83398 10.4602 5.83398 9.99992C5.83398 9.53968 6.20708 9.16658 6.66732 9.16658H9.16732V6.66658C9.16732 6.20635 9.54041 5.83325 10.0007 5.83325Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <span className="text-base font-semibold leading-normal">Create meeting</span>
          </Button>
        </DialogTrigger>
        <DialogContent className="min-w-max" closeButtonClassName="hidden">
          {/* To Remove Warning */}
          <DialogTitle className="hidden" />
          <DialogDescription className="hidden" />
          {loading ? (
            <span className="visually-hidden">Loading...</span>
          ) : (
            <div className="space-y-6">
              {step === 0 &&
                (scheduleId ? (
                  <Scheduler
                    date={date}
                    setDate={setDate}
                    daysOfWeek={daysOfWeek}
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    bookings={bookings}
                  />
                ) : (
                  <div>
                    <div>
                      <h2 className="font-bold text-2xl">No Available Slots</h2>
                    </div>
                    <div className="py-4">
                      <p className="text-gray-500">You currently have no available slots set up in your schedule. Please configure your availability in the back office to start booking meetings.</p>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        className={cn('flex items-center gap-2 bg-black text-white', className)}
                        onClick={() => navigate('/back-office/scheduling')}
                      >
                        {' '}
                        <span>
                          <FiSettings />
                        </span>{' '}
                        <span>Go to setup</span>
                      </Button>

                      <Button
                        className={cn(
                          'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                          className,
                        )}
                        onClick={() => setIsOpen(false)}
                      >
                        {' '}
                        <span>Cancel</span>
                      </Button>
                    </div>
                  </div>
                ))}
              {step === 0 && scheduleId && (
                <Button disabled={!selectedTime} onClick={handleSchedule}>
                  {isSubmitLoading ? (
                    <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                  ) : (
                    'Continue'
                  )}
                </Button>
              )}

              {step === 1 && (
                <div>
                  <GuestUserForm
                    setStep={setStep}
                    step={step}
                    selectedTimezone={selectedTimezone}
                    selectedTime={selectedTime}
                    scheduleId={scheduleId}
                    setSelectedTimezone={setSelectedTimezone}
                    date={date}
                    bookingId={bookingId}
                  />
                </div>
              )}
              {step === 2 && (
                <>
                  <div>
                    {forms?.length === 0 &&
                      <div>
                        <div>
                          <h2 className="font-bold text-2xl">No Available Forms</h2>
                        </div>
                        <div className="py-4">
                          <p className="text-gray-500">You currently have no available forms. Please add atleast one default forms in the back office.</p>
                        </div>
                        <div className="flex gap-2">
                          <Button
                            className={cn('flex items-center gap-2 bg-black text-white', className)}
                            onClick={() => navigate('/back-office/form-builder')}
                          >
                            {' '}
                            <span>
                              <FiPlus />
                            </span>{' '}
                            <span>Go to form setup</span>
                          </Button>

                          <Button
                            className={cn(
                              'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                              className,
                            )}
                            onClick={() => setIsOpen(false)}
                          >
                            {' '}
                            <span>Cancel</span>
                          </Button>
                        </div>
                      </div>}
                    {forms && forms.map((form, i) => (
                      <div key={i} className="flex items-center gap-5">
                        <Checkbox
                          checked={form?._id === selectedForm?._id}
                          onCheckedChange={(e) => {
                            if (form?._id === selectedForm?._id) {
                              setSelectedForm(null);
                            } else {
                              setSelectedForm(form);
                            }
                          }}
                          id="form"
                          className="border-gray-500 rounded"
                        />

                        <div className="w-[500px] bg-[#eeeeee] rounded-lg border border-[#eaecf0] px-6 py-4 justify-between items-center gap-2.5 flex">
                          <label htmlFor="form" className="justify-start items-center gap-3 flex cursor-pointer">
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="file-search-02">
                                <path
                                  id="Icon"
                                  d="M23.3332 18.3335H13.3332M16.6665 25.0002H13.3332M26.6665 11.6668H13.3332M33.3332 17.5002V11.3335C33.3332 8.53323 33.3332 7.1331 32.7882 6.06354C32.3088 5.12273 31.5439 4.35783 30.6031 3.87846C29.5336 3.3335 28.1334 3.3335 25.3332 3.3335H14.6665C11.8662 3.3335 10.4661 3.3335 9.39655 3.87846C8.45574 4.35783 7.69084 5.12273 7.21147 6.06354C6.6665 7.1331 6.6665 8.53323 6.6665 11.3335V28.6668C6.6665 31.4671 6.6665 32.8672 7.21147 33.9368C7.69084 34.8776 8.45574 35.6425 9.39655 36.1219C10.4661 36.6668 11.8662 36.6668 14.6665 36.6668H19.1665M36.6665 36.6668L34.1665 34.1668M35.8332 30.0002C35.8332 33.2218 33.2215 35.8335 29.9998 35.8335C26.7782 35.8335 24.1665 33.2218 24.1665 30.0002C24.1665 26.7785 26.7782 24.1668 29.9998 24.1668C33.2215 24.1668 35.8332 26.7785 35.8332 30.0002Z"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>

                            <p className="text-[#101828] text-sm font-medium leading-tight">Form ({form._id})</p>
                          </label>
                          <button
                            onClick={() => handleTriggerEditFormModal(form)}
                            className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] justify-center items-center gap-1 flex text-[#344054]"
                          >
                            <span className="text-sm font-semibold leading-tight">Edit Form</span>
                            <svg
                              className="size-5"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="edit-05" clipPath="url(#clip0_11380_17018)">
                                <path
                                  id="Fill"
                                  opacity="0.12"
                                  d="M6.6665 11.9378C6.6665 11.5301 6.6665 11.3263 6.71255 11.1345C6.75338 10.9644 6.82072 10.8018 6.9121 10.6527C7.01517 10.4845 7.1593 10.3404 7.44755 10.0521L15.4165 2.0832C16.1069 1.39284 17.2262 1.39284 17.9165 2.0832C18.6069 2.77355 18.6069 3.89284 17.9165 4.5832L9.94758 12.5521C9.65932 12.8404 9.5152 12.9845 9.347 13.0876C9.19788 13.179 9.03531 13.2463 8.86525 13.2871C8.67343 13.3332 8.46961 13.3332 8.06196 13.3332H6.6665V11.9378Z"
                                  fill="black"
                                />
                                <path
                                  id="Icon"
                                  d="M9.1665 3.3332H5.6665C4.26637 3.3332 3.56631 3.3332 3.03153 3.60568C2.56112 3.84536 2.17867 4.22782 1.93899 4.69822C1.6665 5.233 1.6665 5.93307 1.6665 7.3332V14.3332C1.6665 15.7333 1.6665 16.4334 1.93899 16.9682C2.17867 17.4386 2.56112 17.821 3.03153 18.0607C3.56631 18.3332 4.26637 18.3332 5.6665 18.3332H12.6665C14.0666 18.3332 14.7667 18.3332 15.3015 18.0607C15.7719 17.821 16.1543 17.4386 16.394 16.9682C16.6665 16.4334 16.6665 15.7333 16.6665 14.3332V10.8332M6.66648 13.3332H8.06193C8.46959 13.3332 8.67341 13.3332 8.86522 13.2871C9.03528 13.2463 9.19786 13.179 9.34698 13.0876C9.51517 12.9845 9.6593 12.8404 9.94755 12.5521L17.9165 4.5832C18.6069 3.89284 18.6069 2.77355 17.9165 2.0832C17.2261 1.39284 16.1069 1.39284 15.4165 2.0832L7.44753 10.0521C7.15928 10.3404 7.01515 10.4845 6.91208 10.6527C6.8207 10.8018 6.75336 10.9644 6.71253 11.1345C6.66648 11.3263 6.66648 11.5301 6.66648 11.9378V13.3332Z"
                                  stroke="currentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_11380_17018">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={handleSaveAndInvite}
                    className="px-[22px] py-4 text-lg text-white font-semibold font-inter leading-7 h-auto"
                  >
                    {isSubmitLoading ? (
                      <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                    ) : (
                      'Scheduled'
                    )}
                  </Button>
                </>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={isEditFormModalOpen} onOpenChange={handleTriggerEditFormModal}>
        <DialogContent className="min-w-max" closeButtonClassName="text-xl">
          <DialogTitle>
            <p className="text-black text-base font-medium font-inter leading-normal">
              Form customization for meeting id #{meetingId}
            </p>
          </DialogTitle>
          <DialogDescription className="hidden" />
          <FormBuilderForMeeting
            formData={selectedForm}
            date={date}
            time={selectedTime}
            bookingId={bookingId}
            callBack={handleTriggerEditFormModal}
          />
        </DialogContent>
      </Dialog>

      <MeetingScheduleSuccessModalUser
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
        date={date?.toDateString()}
        time={selectedTime}
        timeZone={selectedTimezone}
        bookingId={bookingId}
        scheduleId={scheduleId}
      />
    </>
  );
}

export default CreateMeetingButton;
