/* eslint-disable linebreak-style */
import { useEffect, useState } from 'react';

function DateTime() {
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    const updateTimeAndDate = () => {
      const now = new Date();
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const dayOfWeek = days[now.getDay()];
      const month = months[now.getMonth()];
      const currentDate = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if minutes are single digit

      const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
      const formattedDate = `${dayOfWeek}, ${month} ${currentDate}`;

      setTime(formattedTime);
      setDate(formattedDate);
    };

    updateTimeAndDate(); // Update time and date immediately

    // Update time and date every second
    const intervalId = setInterval(updateTimeAndDate, 1000);

    return () => clearInterval(intervalId); // Cleanup function to clear interval
  }, []);
  return (
    <div className="flex justify-center w-full min-w-max">
      <div className="px-6 py-3 bg-white bg-opacity-80 rounded-lg backdrop-blur-lg justify-center items-center gap-2 inline-flex">
        <div className="text-slate-600 text-base font-medium leading-normal">
          {time}
        </div>
        <div className="w-1 h-1 bg-slate-700 rounded-full mt-1" />
        <div className="text-slate-600 text-base font-medium leading-normal">
          {date}
        </div>
      </div>
    </div>
  );
}

export default DateTime;
