/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { toast } from 'sonner';
import { Button } from '../../../../../components/ui/button';
import Config from '../../../../../config';
import MeetingInfo from './meeting-info';

const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

function GuestUserForm({ setStep, step, setSelectedTimezone, scheduleId, bookingId }) {
  const { addToast } = useToasts();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // Guest
  const [guests, setGuests] = useState([]);

  const [isLoadingAddGuest, setIsLoadingAddGuest] = useState(false);

  const handleBookSchedule = () => {
    setIsSubmitLoading(true);

    const formData = {
      scheduleId,
      bookingId,
    };

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/save_guests`,
      data: formData,
    };

    axios
      .request(config)
      .then((data) => {
        addToast(data?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
        });
        setStep(step + 1);
        setIsSubmitLoading(false);
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        // setStep(step + 1);
        setIsSubmitLoading(false);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleAddGuest = () => {
    if (!name) {
      addToast('Please enter your name', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }
    if (!email) {
      addToast('Please enter your email', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }
    let flag = false;

    // Check if the email is already exist on guest list.
    guests.forEach((guest) => {
      if (guest.email === email) {
        toast.error('Email already exist on guest list', {
          position: 'top-center',
        });
        flag = true;
      }
    });

    if (flag) return;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/send_guest_email`,
      data: formData,
    };
    setIsLoadingAddGuest(true);
    axios
      .request(config)
      .then((data) => {
        setGuests([
          ...guests,
          {
            email,
            name,
          },
        ]);
        addToast('Guest invited successfully.', {
          appearance: 'success',
          autoDismiss: true,
        });
        setName('');
        setEmail('');
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsLoadingAddGuest(false);
      });
  };

  return (
    <div className="space-y-6">
      <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[633px]">
        <MeetingInfo setSelectedTimezone={setSelectedTimezone} />
        <hr className="border-gray-500 lg:hidden" />
        <div className="space-y-4 flex-grow flex flex-col justify-center">
          <label htmlFor="name" className="space-y-1 block">
            <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
            <input
              type="text"
              placeholder="Full name"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
            />
          </label>
          <label htmlFor="email" className="space-y-1 block">
            <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">Email</span>
            <input
              type="email"
              placeholder="you@company.com"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
            />
            {email && !validateEmail(email) && (
              <small className="text-red-500 text-xs ml-2">Invalid Email Address</small>
            )}
          </label>
          <div className="space-y-4">
            <Button
              onClick={handleAddGuest}
              className="bg-white hover:bg-gray-50 text-[#323333] border border-[#878888] text-sm font-semibold leading-tight"
            >
              {isLoadingAddGuest ? (
                <span className="border-2 border-black border-r-transparent rounded-full h-4 w-4 animate-spin" />
              ) : (
                'Add Guest'
              )}
            </Button>

            <div className="space-y-3">
              {guests.length > 0 && <p className="text-sm">Guests</p>}
              {guests.map((guest, index) => (
                <div key={index} className="relative">
                  <input
                    type="email"
                    value={guest.email}
                    readOnly
                    className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <Button onClick={handleBookSchedule}>
          {isSubmitLoading ? (
            <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
          ) : (
            'Continue'
          )}
        </Button>
      </div>
    </div>
  );
}

export default GuestUserForm;
