import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

function Layout({ children }) {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <main className="flex-1 flex flex-col bg-[#F9FAFB]">
        <Topbar />
        <div className="flex-1 overflow-y-scroll scroll-none p-8">
          {children}
        </div>
      </main>
    </div>
  );
}

export default Layout;
