import axios from 'axios';
import Config from '../config';

const getUpcomingMeetings = (page, limit = 10, filter) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/upcoming_meetings?page=${page || 1}&limit=${limit}${filter ? `&${filter}` : ''}`,
  });
};

export default getUpcomingMeetings;
