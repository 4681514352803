/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useEffect, useRef, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import getMeetingHistory from '../../../actions/getMeetingHistory';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../../components/ui/pagination';
import MeetingCardItem from './meeting-card-item';

function MeetingHistory() {
  const [historyData, setHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const datePicker = useRef(null);
  const [value, setValue] = useState([new DateObject()]);

  const handleOpen = () => {
    datePicker.current.querySelector('input').focus();
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      const getData = async () => {
        const { data } = await getMeetingHistory(
          page,
          10,
          value[0] && value[1] && `from=${value[0].format('MM-DD-YYYY')}&to=${value[1].format('MM-DD-YYYY')}`,
        );
        // console.log(data.data);
        setHistoryData(data.data);
      };
      getData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [page, value]);

  return (
    <div className="space-y-6 px-6 py-6 rounded-xl" style={{ background: '#F2F4F7' }}>
      <div className="flex items-center justify-between">
        <h5 className="text-gray-900 text-lg font-semibold leading-7">Meeting History</h5>

        <div className="flex gap-4">
          <button className="px-3.5 py-2.5 gap-2.5 bg-white rounded-lg shadow border border-gray-300 inline-flex">
            <img src="/image/icons/calendar.svg" alt="Calender" className="w-5 h-5" />
            <button onClick={handleOpen} className="px-0.5 text-gray-500 text-sm font-semibold">
              {' '}
              {value[0] && value[1]
                ? `${value[0].format('DD/MM/YYYY')} - ${value[1].format('DD/MM/YYYY')}`
                : 'Select Date'}
            </button>
            <DatePicker inputClass="w-0 m-0 p-0" ref={datePicker} value={value} onChange={setValue} rangeHover range />
          </button>
        </div>
      </div>

      {isLoading ? (
        <p className="flex items-center gap-2">
          <span className="w-4 h-4 rounded-full border-t-black border-2 border-gray-500 block animate-spin" />
          <span className="animate-pulse">Loading meeting history...</span>
        </p>
      ) : (
        <>
          <div className="space-y-4 max-h-[50vh] overflow-y-scroll scroll-none">
            {historyData.map((item) => (
              <MeetingCardItem key={item._id} data={item} />
            ))}
          </div>
          {historyData?.length === 0 && page === 1 && <p className="text-gray-500">No meetings found.</p>}
          {historyData?.length === 0 && page > 1 && <p className="text-gray-500">No meetings found at this page.</p>}
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))}
                  className={page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>{page}</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationNext
                  onClick={() =>
                    setPage((prev) => (historyData?.length === 0 || historyData?.length < 10 ? prev : prev + 1))
                  }
                  className={
                    (historyData?.length === 0 && page > 1) || historyData?.length < 10
                      ? 'text-gray-500 cursor-not-allowed'
                      : 'text-gray-800'
                  }
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </>
      )}
    </div>
  );
}

export default MeetingHistory;
