import { Button } from '../../../components/ui/button';

function PaymentMethod() {
  return (
    <div className="bg-white rounded-xl shadow border border-gray-200 p-6 space-y-4 w-full">
      <div className="flex-col justify-start items-start gap-6 flex w-full">
        <div className="flex-col justify-start items-start gap-1 inline-flex">
          <div className="items-center flex gap-3">
            <div className="text-gray-900 text-lg font-semibold leading-7">
              Payment method
            </div>
          </div>
          <p className="text-slate-600 text-sm font-normal leading-tight m-0">
            Change how you pay for your plan.
          </p>
        </div>
      </div>
      <div className="p-4 bg-white rounded-lg border border-gray-200 gap-4">
        <div className="justify-start items-start gap-4 flex">
          <div className="relative bg-white rounded-md border border-gray-200">
            <img className="" src="/image/icons/visa.svg" alt="" />
          </div>
          <div className="space-y-2 w-full">
            <div className="flex justify-between w-full">
              <div>
                <div className="text-slate-700 text-sm font-medium leading-tight">Visa ending in 1234</div>
                <div className="text-slate-600 text-sm font-normal leading-tight">Expiry 06/2024</div>
              </div>
              <Button className="text-slate-700 text-sm font-semibold leading-tight bg-white border hover:bg-gray-50">Edit</Button>
            </div>
            <div className="flex items-center gap-1.5">
              <img src="/image/icons/mail.svg" alt="" className="w-4 h-4 relative" />
              <div className="text-slate-600 text-sm font-normal leading-tight">billing@untitledui.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethod;
