import { useGlobal } from 'reactn';
import Config from '../../../config';
import CreateMeetingButton from './create-meeting-button';
import NotificationButton from './notification-button';

function Topbar() {
  const user = useGlobal('user')[0];
  return (
    <div className="py-[10px] px-8 bg-white border-b border-gray-200 justify-end items-center gap-[15px] inline-flex">
      <CreateMeetingButton className="px-3 py-2 text-sm font-inter" />
      <NotificationButton />
      <button className="justify-start items-center gap-2.5 inline-flex">
        <div className="w-8 h-8 rounded-full justify-center items-center flex overflow-hidden">
          <img src={user?.picture ? `${Config?.url}${user?.picture.location.slice(1)}` : '/image/placeholder/avater2.png'} alt="Avatar" className="w-8 h-8 relative rounded-full border border-black border-opacity-10 object-cover object-center" />
        </div>
        <div className="flex-col justify-start items-start inline-flex">
          <div className="text-slate-700 text-xs font-medium leading-[18px]">
            {user?.firstName}
            {' '}
            {user?.lastName}
          </div>
        </div>
      </button>
    </div>

  );
}

export default Topbar;
