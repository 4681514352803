import { useGlobal } from 'reactn';

function WelcomeHeading() {
  const user = useGlobal('user')[0];

  return (
    <h2 className="text-gray-900 text-3xl font-semibold leading-[38px]">
      Hey,
      {' '}
      {user?.firstName}
      {' '}
      {user?.lastName}
    </h2>
  );
}

export default WelcomeHeading;
