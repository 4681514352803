/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from '../../../../../components/ui/button';
import { Input } from '../../../../../components/ui/input';
import Config from '../../../../../config';
import { formatDateString } from '../../../../../lib/utils';
import MeetingScheduleSuccessModal from '../../../components/meeting-schedule-success-modal';

function FormSubmission({
  fields, setFields, scheduleId, bookingId, date, selectedTime, selectedTimezone, meetingId, isFormAdded, formSubmissionObj,
}) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleRestartSteps = () => {
    setActiveIndex(1);
    setFields((prev) => prev.map((field) => {
      return {
        ...field,
        answer: '',
      };
    }));
  };

  const handleSubmitScheduleForm = () => {
    const fieldsData = fields.map((field) => {
      return {
        fieldId: field._id,
        answer: field.answer,
      };
    });

    // formData.append('fields', JSON.stringify(fieldsData));

    const formData = {
      scheduleId,
      bookingId,
      date: formatDateString(date),
      time: selectedTime,
      fields: fieldsData,
    };

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/submit_booking_form`,
      data: formData,
    };

    //   show error if any of them are empty
    if (!selectedTime) {
      toast.error('Please select a time.', {
        position: 'top-center',
      });
      return;
    }

    setIsSubmitLoading(true);
    axios.request(config)
      .then((data) => {
        // console.log(data?.data?.message);
        toast.success(data?.data?.message, {
          position: 'top-center',
        });
        setShowSuccessModal(true);
        // setStep(0);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        setShowSuccessModal(true);
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  useEffect(() => {
    if (isFormAdded) {
      setShowSuccessModal(true);
    }
  }, [isFormAdded]);

  return (
    <div>
      <div className="bg-[#E2E8F0] rounded-lg overflow-hidden min-w-5xl min-h-[500px] shadow-md flex flex-col">
        <div className="bg-[#F1F5F9] w-full h-full flex items-center justify-end gap-2 px-4 py-2">
          <button onClick={handleRestartSteps} className="px-2 py-0.5 bg-white rounded justify-center gap-[4.29px] flex">
            <p className="m-0 text-center text-slate-500 text-[12px] font-medium leading-3">Restart</p>
            <img src="/image/icons/repeat.svg" alt="" className="w-[12px] h-[12px] relative flex-col justify-start items-start flex" />
          </button>
        </div>
        <div className="flex-grow flex justify-center items-center p-20">
          {
                        (fields.length > 0) && activeIndex > 0 && (
                        <div className="p-6 bg-white shadow-lg rounded-lg min-w-[400px] space-y-6">
                          {/*  */}
                          <label htmlFor={fields[activeIndex - 1].question.toLocaleLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')} className="text-slate-900 text-sm font-semibold leading-none">
                            {fields[activeIndex - 1].question ? fields[activeIndex - 1].question : 'Who let the dogs out ?'}
                            {fields[activeIndex - 1].required && <span className="text-red-500"> *</span>}
                          </label>
                          {
                                    fields[activeIndex - 1].fieldType === 'textarea'
                                      ? (
                                        <textarea
                                          className="w-full border focus:outline-none p-2 rounded-lg"
                                          placeholder="Type your answer here..."
                                          id={fields[activeIndex - 1].question.toLocaleLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}
                                          name={fields[activeIndex - 1].question.toLocaleLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}
                                          value={fields[activeIndex - 1].answer}
                                          onChange={(e) => setFields((prev) => prev.map((field) => (field.question === fields[activeIndex - 1].question ? { ...field, answer: e.target.value } : field)))}
                                        />
                                      )
                                      : (
                                        <Input
                                          type={fields[activeIndex - 1].fieldType === 'phone' ? 'tel' : fields[activeIndex - 1].fieldType}
                                          id={fields[activeIndex - 1].question.toLocaleLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}
                                          placeholder="Type your answer here..."
                                          name={fields[activeIndex - 1].question.toLocaleLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}
                                          value={fields[activeIndex - 1].answer}
                                          onChange={(e) => setFields((prev) => prev.map((field) => (field.question === fields[activeIndex - 1].question ? { ...field, answer: e.target.value } : field)))}
                                        />
                                      )
                                }
                          <div className="flex justify-end">
                            {
                                        (fields.length) === activeIndex ? (
                                          <div className="flex items-center gap-2">
                                            <Button
                                              className={`${!fields[activeIndex - 1].answer ? 'cursor-not-allowed bg-gray-400 hover:bg-gray-400' : ''}`}
                                              onClick={() => {
                                                if (fields[activeIndex - 1].answer) {
                                                  handleSubmitScheduleForm();
                                                }
                                              }}
                                            >
                                              {
                                                    isSubmitLoading ? <span className="border border-white rounded-full h-4 w-4 animate-spin border-t-transparent" /> : 'Finish'
                                                  }
                                            </Button>
                                          </div>
                                        ) : (
                                          <div className="flex items-center gap-2">
                                            {
                                                    activeIndex > 1 && (
                                                    <Button onClick={() => setActiveIndex(activeIndex - 1)}>
                                                      Prev
                                                    </Button>
                                                    )
                                                }
                                            <Button
                                                    //   disabled={!fields[step - 1].answer}
                                              className={`${!fields[activeIndex - 1].answer ? 'cursor-not-allowed bg-gray-400 hover:bg-gray-400' : ''}`}
                                              onClick={() => {
                                                if (fields[activeIndex - 1].answer) {
                                                  setActiveIndex(activeIndex + 1);
                                                }
                                              }}
                                            >
                                              Next
                                            </Button>
                                          </div>
                                        )
                                    }

                          </div>
                        </div>
                        )
                    }
        </div>
      </div>

      <MeetingScheduleSuccessModal
        open={showSuccessModal}
        setOpen={(val) => {
          setShowSuccessModal(val);
          navigate('/');
        }}
        date={date?.toDateString()}
        time={selectedTime}
        timeZone={selectedTimezone}
        scheduleId={scheduleId}
        bookingId={bookingId}
        url={`/meeting/${meetingId}`}
      >
        {/*  */}
        <div className="bg-white p-3 py-4 rounded-2xl w-full space-y-6">
          {/*  */}
          <div className="text-gray-900 text-lg font-semibold leading-7">QA Response</div>
          <div className="max-h-[100px] overflow-auto space-y-6">
            {
            formSubmissionObj?.fields && formSubmissionObj?.fields?.length !== 0
              ? formSubmissionObj?.fields.map((item, i) => (
                <div key={i} className="space-y-2.5">
                  <h4 className="text-slate-600 text-sm font-semibold leading-tight mb-0">
                    {item.question}
                  </h4>
                  <p className="text-slate-600 text-sm font-normal leading-tight">
                    {formSubmissionObj.answers[i]}
                  </p>
                </div>
              ))
              : fields.map((item, i) => (
                <div key={i} className="space-y-2.5">
                  <h4 className="text-slate-600 text-sm font-semibold leading-tight mb-0">
                    {item.question}
                  </h4>
                  <p className="text-slate-600 text-sm font-normal leading-tight">
                    {item.answer}
                  </p>
                </div>
              ))
              }
          </div>
        </div>
      </MeetingScheduleSuccessModal>
    </div>
  );
}

export default FormSubmission;
