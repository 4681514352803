/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dialog, DialogContent,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import { convertTo12Hour } from '../../../lib/utils';

function MeetingScheduleSuccessModal({
  url,
  open,
  setOpen,
  date,
  time,
  timeZone,
  bookingId,
  scheduleId,
  children,
}) {
  // const [open, setOpen] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (scheduleId && bookingId && open) {
      const config = {
        method: 'get',
        url: `${Config.url || ''}/api/v1/public/get_booking/${scheduleId}/${bookingId}`,
      };
      axios.request(config)
        .then(({ data }) => {
          console.log(data.data, 'Booking Data');
          setData(data.data);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  }, [scheduleId, bookingId, open]);

  return (
    <Dialog open={open} onOpenChange={setOpen} className="">
      <DialogContent className="bg-[#EAECF0] max-w-[641px]">
        <div className="flex flex-col items-center justify-center gap-3 ">
          <img src="/image/icons/check-success.svg" alt="" />
          <div className="w-full text-center px-6 space-y-2.5">
            <h2 className=" m-0 text-lg font-semibold">
              This meeting is scheduled
            </h2>
            <p className="m-0 text-sm">We sent an email with a calendar invitation with the details to everyone.</p>
          </div>
          <div className="bg-white p-3 py-4 rounded-2xl space-y-6">
            <h4 className="text-gray-900 text-lg font-semibold leading-7">Meeting Details</h4>
            <div className="grid grid-cols-2 gap-x-6">
              <div className="space-y-6">
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">What</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                    15 Min Meeting
                  </p>
                </div>
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">When</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                    {date}
                    {' - '}
                    {convertTo12Hour(time)}
                    {' '}
                    (
                    {timeZone}
                    )
                  </p>
                </div>
              </div>
              <div className="space-y-6">
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Who</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                    {data?.schedule?.user?.firstName}
                    {' '}
                    {data?.schedule?.user?.lastName}
                    <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                      Host
                    </button>
                  </p>
                  <p className="text-slate-600 text-sm font-normal leading-tight">{data?.schedule?.user?.email}</p>
                </div>
                {
                  data?.booking?.guests.map((item, i) => (
                    <div className="space-y-[10px]" key={i}>
                      <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                        {item?.name}
                        {' '}
                        <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                          Guest
                        </button>
                      </p>
                      <p className="text-slate-600 text-sm font-normal leading-tight">{item?.email}</p>
                    </div>
))
                }

              </div>
              <div />
            </div>
            <div className="space-y-[12px]">
              <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Where</p>
              <Link to={url} className="text-slate-600 text-sm font-semibold font-['Inter'] leading-tight">Saybriefly Video</Link>
            </div>
            <div className="items-center gap-1 flex justify-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
              Need to make a change?
              <button className="underline">Reschedule</button>
              or
              <button className="underline">Cancel</button>
            </div>
          </div>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MeetingScheduleSuccessModal;
