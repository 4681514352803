function Input({
  placeholder, type, onChange, label, disabled = false, ...rest
}) {
  const randomID = (Math.random() * 100000).toString();
  return (
    <div>
      <label htmlFor={`input${randomID}`} className="text-slate-700 text-sm font-medium leading-tight mb-2 block">{label}</label>
      <div className="border-2 overflow-hidden rounded-lg bg-white">
        {/* <span className="" data-uk-icon={`icon: ${icon}`} onChange={onChange} /> */}
        <input {...rest} id={`input${randomID}`} disabled={disabled} className="p-2 focus:bg-white focus:outline-none w-full" required placeholder={placeholder} type={type} onChange={onChange} />
      </div>
    </div>
  );
}

export default Input;
