/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { toast } from 'sonner';
import { Calendar } from '../../../../../components/ui/calendar';
import { cn, convertTo12Hour, generateTimeArray } from '../../../../../lib/utils';
import { isPastDate } from '../scheduling';
import MeetingInfo from './meeting-info';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

function isSameDate(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear()
    && d1.getMonth() === d2.getMonth()
    && d1.getDate() === d2.getDate()
  );
}

const shouldDisable = (bookings, date, timeStr) => bookings.some((booking) => isSameDate(date, booking.bookingDate) && booking.bookingTime === timeStr);

function Scheduler({
  setSelectedTimezone, date, setDate, daysOfWeek, setSelectedTime, selectedTime, bookings,
}) {
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormat[0]);
  return (
    <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[1400px]">
      <MeetingInfo setSelectedTimezone={setSelectedTimezone} />
      <hr className="border-gray-500 lg:hidden" />
      <Calendar
        mode="single"
        selected={date}
        onSelect={(d) => {
          if (!isPastDate(d)) {
            setDate(d);
          } else {
            toast.error('You can not select a previous date.', {
              position: 'top-center',
            });
          }
        }}
        className="flex justify-center lg:pr-6 lg:border-r-2 border-neutral-300 lg:col-span-2"
        classNames={{
          head_cell: 'w-16',
          cell: 'w-16 h-16',
        }}
      />
      <hr className="border-gray-500 lg:hidden" />
      <div className="space-y-6">
        <div className="flex items-center lg:justify-between justify-center gap-6">
          {/*  */}
          <p className="flex gap-[12px] items-center m-0">
            <span className="text-neutral-700 text-base font-semibold leading-normal">
              {date && days[new Date(date).getDay()]}
            </span>
            <span className="text-zinc-500 text-sm font-medium leading-tight">
              {date && new Date(date).getDate()}
            </span>
          </p>
          <div className="px-1 py-.5 rounded-md border border-neutral-400 justify-end items-center gap-[13.66px] inline-flex">
            {
              timeFormat.map((item, i) => (
                <div key={i} onClick={() => setSelectedTimeFormat(item)} className={`px-3 py-[4.25px] cursor-pointer ${selectedTimeFormat === item ? 'bg-neutral-700 rounded text-center text-zinc-300 text-sm font-medium leading-[17.50px]' : ''}`}>
                  {item}
                </div>
              ))
            }
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 max-h-[550px] overflow-auto p-2">
          {(daysOfWeek[new Date(date).getDay()]?.startTime || daysOfWeek[new Date(date)?.getDay()]?.endTime) && generateTimeArray(daysOfWeek[new Date(date).getDay()]?.startTime, daysOfWeek[new Date(date)?.getDay()]?.endTime, '24h')?.map((item, i) => (
            <button
              key={i}
              onClick={() => setSelectedTime((prev) => {
                if (prev === item) {
                  return '';
                }
                return item;
              })}
              disabled={shouldDisable(bookings, date, item)}
              className={cn(
                `px-12 py-2 rounded-lg border border-gray-300 text-slate-700 text-sm font-semibold leading-tight ${selectedTime === item ? 'bg-neutral-700 text-white' : 'bg-white'}`,
                shouldDisable(bookings, date, item) && 'cursor-not-allowed bg-red-50',
              )}
            >
              {selectedTimeFormat === '12h' && item ? convertTo12Hour(item) : item}
            </button>
          ))}
        </div>

      </div>
    </div>
  );
}

export default Scheduler;
