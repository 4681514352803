import axios from 'axios';
import Config from '../config';

const resetPassword = (email, code, newPassword) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/auth/reset-password`,
    data: { email, code, newPassword },
  });
};

export default resetPassword;
