/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */

import { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import DateTime from '../../../components/date-time';

export default function TopBarTransparent({ localStream, video, isScreen }) {
    const localVideoRef = useRef(null);

    useEffect(() => {
        if (!localVideoRef) return;
        localVideoRef.current.srcObject = localStream;
        // console.log(localStream, 'localStream');
    }, [localStream]);

    return (
        <div className="meeting-top-controls-transparent relative">
            <div
                className="m-6"
            >
                <DateTime />
            </div>
            <div className="videos m-6" style={{ flexGrow: 0, minWidth: video || isScreen ? 137 : 0 }}>
                <video
                    hidden={!video && !isScreen}
                    className="video rounded-xl"
                    onLoadedMetadata={() => localVideoRef.current.play()}
                    ref={localVideoRef}
                    style={{ objectFit: 'cover', zIndex: 1000 }}
                    playsInline
                />
            </div>
        </div>
    );
}
