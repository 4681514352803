/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */

import { useState } from 'react';
import axios from 'axios';
import { toast } from 'sonner';
import { Input } from '../ui/input';
import config from '../../config';

/* eslint-disable react/jsx-indent */
export default function ContinueAsGuestForm({ entryPath, onGuestContinue }) {
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Function to handle guest registration
    const onRegisterGuest = async (e) => {
        e.preventDefault(); // Prevent form submission

        // Validate form fields
        if (!registerEmail || !registerFirstName || !registerLastName) {
            toast.error('Please fill out all the required fields.');
            return;
        }

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(registerEmail)) {
            toast.error('Please enter a valid email.');
            return;
        }

        setIsLoading(true);

        const payload = {
            username: registerEmail.split('@')[0],
            email: registerEmail,
            firstName: registerFirstName,
            lastName: registerLastName,
            fullname: `${registerFirstName} ${registerLastName}`,
            entryPath,
        };

        try {
            // Send the guest registration request
            const { data: res } = await axios.post(`${config.url || ''}/api/v1/auth/continue-as-guest`, payload);

            if (res.success) {
                onGuestContinue(res); // Continue with guest login
                toast.success('Thank you!', {
                    position: 'top-center',
                });
            } else {
                toast.error(res.message || 'Guest registration failed.', {
                    position: 'top-center',
                });
            }
        } catch (error) {
            // Handle server errors
            console.error('Registration error:', error?.response);
            toast.error(error?.response?.data?.error || 'An error occurred during registration. Please try again.', {
                position: 'top-center',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={onRegisterGuest} className="space-y-6">

            <Input
                icon="mail"
                placeholder="Email"
                label="Email"
                type="email"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)}
            />
            <Input
                icon="pencil"
                placeholder="First Name"
                label="First Name"
                type="text"
                value={registerFirstName}
                onChange={(e) => setRegisterFirstName(e.target.value)}
            />
            <Input
                icon="pencil"
                placeholder="Last Name"
                label="Last Name"
                type="text"
                value={registerLastName}
                onChange={(e) => setRegisterLastName(e.target.value)}
            />

            <div>
                <button
                    type="submit"
                    className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal"
                >
                    {
                        isLoading ? (
                            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                        ) : (
                            <span>
                                Get Started
                            </span>
                        )
                    }
                </button>
            </div>
        </form>
    );
}
